.carouselWrap {
  position: relative;
}

/* control div is also used to measure carousel item width */ 
.controls {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;    
}

.controls svg {
  font-size: 48px;
}

.carousel {
  display: flex;
  align-items: center;
  composes: horizontalScroll from '../common/mixins.css';     
}

.carouselItem {
  max-width: 220px;
  flex: 0 0 100%;
  margin-right: 1rem;  /* this margin is part of the carousel item width calc! */    
}

.carouselItem:last-of-type {
  margin-right: 0;
}

.scrollArrow {
  cursor: pointer;
  position: absolute;
  display: none;
  opacity: 0;
  z-index: 2;
  top: 50%;
  transform: translateY(-55%);
  width: 48px;
  height: 48px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  border-radius: 50%;
  transition: opacity .5s ease-in-out;
}
  
.scrollLft {
  left: -15px;
}

.scrollRgt {
  right: -15px;
}

.withLeftArrow .scrollLft,
.withRightArrow .scrollRgt {
  display: block;
  opacity: 1;
}

@media screen and (max-width:800px) {
  .scrollArrow {
    width: 32px;
    height: 32px;
  }
  .controls svg {
    font-size: 32px;
  }
  .carouselItem {
    max-width: 150px;
  }    
}

@media screen and (max-width:480px) {
  .carousel {
    overflow-x: visible;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .controls {
    display: none;
  }
  .carouselItem {
    max-width: calc(50% - .5rem);
    margin-right: 0;
    margin-bottom: .5rem;
  }
  
}