.imageGrid {
  display: flex;
  margin: .5rem auto;
}
  
.imageItem {
  flex: 1;
}
  
.imageWrapper {
  position:relative;
}
  
.imageWrapper img {
  width: 90%;
  margin: 0 0 .5rem;
  border-radius: 1rem;
}

.placeholderItem img {
  width: 90%;  
  background-color: #ccc;
  border-radius: 1.5rem;
}

/* upload via placeholder click */
.placeholderItem img:first-of-type {
  cursor: pointer;
}

.placeholderLabel {
  font-size: 20px;
  font-weight: 700;
  margin: 1rem 0 .5rem;
}

.alternateButton {
  cursor: pointer;
  white-space: nowrap;
  background-color: #e7e7e7;
  color: #000;
  padding: .33rem .75rem;
  border: none;
  border-radius: 1rem;
  font-size: .75rem;
  font-weight: 700;
}
  
.imageUploadButtons {
  margin-top: 1rem;
  flex: 0 0 100%;
  text-align: center;
}
  
.imageUploadButtons button {
  width: auto;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.progressWrap {
  flex: 0 0 100%;
  margin: 1rem 0 -1rem;
}

.updateMsg {
  margin: 1rem .5rem 2rem;
  text-align: center;
}

.photoIDInstructions {
  margin: .5rem .5rem 2rem;
  line-height: 150%;
  text-align: center;
}

.statusText {
  font-weight: 600;
}

@media (max-width:480px) {
  .imageGrid { display:block; }
  .imageItem { margin: 0 0 1.5rem; }
  .photoIDInstructions span {
    display: block;
  }
}