.pageHeader {
  display: flex;
  align-items: center;
  height: 4rem;
  color: #000;
  font:var(--zrFont);
  font-size: .875rem;
  /* dutchie */
  border-bottom: 2px solid #d6dadd;  /* outer 1px is #d7e4eb */
  box-shadow: rgb(0 0 0 / 4%) 0px 3px 1px;

  background-color: #f3f6f8;
  box-sizing: border-box;
  width: 100vw;
} 

.navLink {
  cursor: pointer;
  margin: 0 .5rem;
}

.navLink:hover {
  text-decoration: underline;
}

.burgerWrap,
.cartWrap {
  flex: 0;
}

.cartWrap {
  display: flex;
  flex: 0;
  margin-right: 1rem;
  align-items: center;
}

.centerArea {
  flex: 1;  
}

@media screen and (max-width:600px) {
  .themeToggle {
    display: none;
  }
}