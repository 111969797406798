/* AddressTypeAhead */

.registerForm {
  width: 500px;
  max-width: 93vw;
  box-sizing: border-box;
  margin: 0 auto;
  padding-bottom: 2rem;
}
  
.inputWrap {
  white-space: nowrap;
  position: relative;
}

.inputWrap :global .MuiFormControl-root.MuiTextField-root {
  margin-bottom: 0;
  display: block;
}

.inputWrap :global .MuiTextField-root .MuiInputLabel-filled {
  padding-left: 1.125rem;
  padding-right: 4rem;    
}

.inputWrap :global .MuiInputBase-root {
  display: block;
  white-space: nowrap;
}

.inputWrap :global .MuiFilledInput-input {
  box-sizing: border-box;
  height: 54px;
  padding: 25px 1rem 14px 2.25rem;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.inputWrap :global .MuiTextField-root .MuiInputLabel-filled.MuiInputLabel-shrink {
  display: none;  
  transform: translate(8px, 8px) scale(0.75);
}

.inputWrap .pinIcon {
  position: absolute;
  left: .5rem;
  top: 22px;
  z-index: 1;
}

.inputWrap .pinIcon svg {
  font-size: 20px;
}
    
@media screen and (max-width:640px) {
  .inputWrap.wide {
    width: 420px;
  }
}

@media screen and (max-width:480px) {
  .inputWrap.wide,
  .inputWrap {
    width: auto;
    max-width: none;
  }

  .inputWrap :global .MuiFilledInput-input {
    padding-right: 4rem;
  }

}
  
/* START AddressUpdaterInline component */
.addressForm {
  margin: 0 0 1rem;    
}

.formWrap {
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.025);       
}

.addressWithEditButton {
  position: relative;    
}

.addressWithEditButton .fauxLabel {
  position: absolute;
  left: 1rem;
  top: 8px;
  font-size: .75rem;
  color: rgba(0, 0, 0, 0.54);
}

.addressWithEditButton button {
  position: absolute;
  top: 50%;
  line-height: 1.75rem;
  height: 39px;
  right: 6px;
  transform: translateY(-50%);    
}

.formattedAddress {
  text-align: left;  
  font-size: 1rem;
  border-radius: 3px 3px 0 0;
  color: #000;  
  background-color: rgba(0, 0, 0, 0.05);    
  padding: 25px 5rem 10px 1rem;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
/* End AddressUpdaterInline component */

.errorMsg {
  color: var(--zrTextRed);
  font-size: .8rem;
  margin:.25rem 0 1rem;
}
.errorMsg svg { 
  vertical-align: -4px;
  font-size: 18px;
  margin-right: 4px;
}
/* AddressForm EDIT component */
.addressInputTriple { 
  display: flex;
  flex-wrap: wrap;
}

.addressInputTriple :global .MuiFormControl-root.MuiTextField-root {
  flex: 1;
  margin-right: 1rem;
} 

.addressInputTriple :global .MuiFormControl-root.MuiTextField-root:first-of-type {
  flex: 1.75;    
}

.addressInputTriple :global .MuiFormControl-root.MuiTextField-root:last-of-type {
  margin-right: 0;
}

.formButtonWrap {
  text-align: right;    
}
  
.formButtonWrap button {
  min-width: 104px; /* match grid */  
  margin-left: 1rem;    
}

@media screen and (max-width:480px) { 
  .addressInputTriple :global .MuiFormControl-root.MuiTextField-root:first-of-type { 
    flex-basis: 100%;
    margin-right: 0;    
  }    
}

body[data-route="inline"] :global .pac-container {
  box-shadow: 0 0 4px rgb(180 180 180 / 30%);
}

/* Location search actuallly uses placeholder text */
.inputWrap :global .MuiFilledInput-root .MuiFilledInput-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.54) !important;
}