/* close icon */
:global #burgerIcon { 
  position: relative;
  z-index:4; 
}

/* Burger Menu Items */
.menuItems {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 3rem;
  background-color: #fff;
  transform: translateX(-105%);
  height: 100vh;
  text-align: left;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
}

.open.menuItems {
  transform: translateX(0);
  box-shadow: 0 0 24px #ccc;
}

.menuItem {
  box-sizing: border-box;
  min-width: 340px;
  font: var(--zrFont);  
  font-size: 1.125rem;
  font-weight: 700;
  padding: 1rem 2rem;
  color: #000;
  text-decoration: none;
  transition: color 0.3s linear;
  border-bottom: 1px solid #e6e7e8;
  outline: none;
  cursor: pointer;
}

.menuItem:focus,
.menuItem:active {
  outline: none;
}

.menuItem:first-child {
  border-top: 1px solid #e6e7e8;        
}

.menuItem:hover {
  background-color: #f7f7f7;
  /* color: var(--zrGreen); */
}

@media screen and (max-width: 480px) {
  .menuItems {
    width: 100%;
  }
}
