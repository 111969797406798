.creditBanner {
  position: relative;
  box-sizing: border-box;
  min-height: 110px;
  margin: 1rem 0;
  border-radius: .25rem;;
  background-color: var(--zrDarkBlue);
  padding: 1.75rem 2rem 2rem;
  overflow: hidden;
}
.creditMessage {
  position: absolute;
  top: 50%;
  left: 195px;
  right: 2rem;
  transform: translateY(-50%);
  color: #fff;
  font: var(--zrFont);
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 145%;
}
.details {
  white-space: nowrap;
}
.creditCount {
  display: inline-block;
  background-color: #fff;
  border-radius: 2px;
  color: var(--zrGreen);
  line-height: 120%;
  padding: 0 7px;
}
.creditImage {
  position: absolute;
  top: 1rem;
  left: 3rem;
  width: 120px;  
}
.disclaimer {
  font-size: .875rem;
  line-height: 175%;
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 500;
}
  
@media screen and (max-width:800px) {
  .creditImage {
    left: 2rem;
  }
  .creditMessage {
    left: 175px;
    font-size: 1.375rem;
  }
}

@media screen and (max-width:680px) {
  .creditBanner {
    min-height: 110px; 
  }
  .creditImage {
    width: 72px;
    left: 1.375rem;
    top: .75rem;
  }
  .creditMessage {
    font-size: 1.125rem;
    line-height: 120%;
    left: 115px;
  }
  .salutation {
    display: block;
  }
  .details {    
    white-space: normal;
  } 
  .disclaimer {
    line-height: 140%;
  }
}