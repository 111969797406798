/* We're only using the ProductType sub-component */  
.productType {
  cursor: pointer;
  flex: 0 1 calc(25% - 12px);
  position: relative;
  border-radius: .5rem;
  min-height: 180px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 10px;
  border-radius: 1.5rem;
}

.productType:hover {
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 14px;    
}

.nameSubHead {
  color: #999;
  font-size: .75rem;
  line-height: 1rem;
  font-weight: 600;
}

.name {
  position: absolute;
  top: 0.75rem;
  left: 1.25rem;
  font: var(--zrHeaderFont);
  font-size:1.25rem;    
  font-weight: 700;    
}
  
.icon {
  margin-top: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);   
}

.icon img {
  max-width: 94px; /* 200x200 actual */
}

@media screen and (max-width: 800px) {
  .productType {
    min-height: 133px;
  }  
  .name {
    font-size: 1.125rem;
  }
  .icon {
    margin-top: 1.25rem;
  } 
  .icon img {
    max-width: 66px;  
  }
}  

@media screen and (max-width: 480px) {
  .productType {
    min-height: 140px;
    flex: 0 1 calc(50% - 12px); 
  }
  .name {
    font-size: 1rem;
  }
  .nameSubHead {
    font-size: .675rem;
  }  
  .icon {
    margin-top: 1.375rem;
  }      
  .icon img {
    max-width: 75px; /* 200x200 actual */
  }
}