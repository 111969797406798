.cartIconWrapper {
  padding: 5px 18px 5px 8px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: #bccad2;
  border-radius: 6px;
  color: #fff;
}

.cartIconWrapper svg {
  vertical-align: middle;
}

.itemCount {
  vertical-align: -3px;
  text-align: right;
  font-weight: 700;
  font-size: 1.125rem;
  margin-left: 0.5rem;
}