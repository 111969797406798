/* Register / AddressTypeAhead */

.registerForm {
  width: 500px;
  max-width: 93vw;
  box-sizing: border-box;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.inputWrap {
  width: 480px;
  white-space: nowrap;
  position: relative;
}

.inputWrap.wide {
  width: 560px; /* 2021 launch home page iframe */ 
}

.inputWrap :global .MuiFormControl-root.MuiTextField-root {
  display: block;
}

.inputWrap :global .MuiTextField-root .MuiInputLabel-filled {
  padding-left: 2.25rem;
  padding-right: 4rem;    
}

.inputWrap :global .MuiInputBase-root {
  display: block;
  white-space: nowrap;
}

.inputWrap :global .MuiFilledInput-input {
  box-sizing: border-box;
  height: 48px;
  padding: 14px 8rem 14px 2.25rem;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.inputWrap :global  .MuiTextField-root .MuiInputLabel-filled {
  transform: translate(0px, 16px) scale(1);
}

.inputWrap :global .MuiTextField-root .MuiInputLabel-filled.MuiInputLabel-shrink {
  display: none;  
  transform: translate(8px, 8px) scale(0.75);
}

.inputWrap .pinIcon {
  position: absolute;
  left: .5rem;
  top: 13px;
  z-index: 1;
}

.inputWrap .pinIcon svg {
  font-size: 20px;
}

.inputWrap .continueButton {
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 3px;
  height: 41px;
  padding-left: .75rem;
  padding-right: .75rem;
  background-color: var(--zrGreen);
  color: #fff;
  font: var(--zrHeaderFont);
  font-size: 1.125rem;
  font-weight: 800;
  border-radius: .25rem;
  border: none;
}

.inputWrap .continueButton.enabledButton:hover {
  color: #fff;
  background-color: var(--zrDarkBlue);
}

.inputWrap .continueButton svg {
  display: none;
  font-size: 28px;
}

.inputWrap.arrowOnly .continueButton svg {
  display: block;
}  

.inputWrap .continueButton.disabledButton {
  cursor: default;
  /* opacity: .5; */
}

@media screen and (max-width:640px) {
  .inputWrap.wide {
    width: 420px;
  }
}

@media screen and (max-width:480px) {
  .inputWrap.wide,
  .inputWrap {
    width: auto;
    max-width: none;
  }

  .inputWrap :global .MuiFilledInput-input {
    padding-right: 4rem;
  }

  .inputWrap .continueButton .buttonText {
    display: none;
  }

  .inputWrap .continueButton svg {
    display: block;
  }
}

/* AddressUpdater component */

.addressForm {
  margin: 0 0 1rem;    
}

.formWrap {
  padding: 1rem;
  border-radius: .25rem;
  border: 1px solid #fff;    
}

.addressWithEditButton {
  position: relative;    
}

.addressWithEditButton .fauxLabel {
  position: absolute;
  left: 1rem;
  top: 8px;
  font-size: .75rem;
  color: rgba(0, 0, 0, 0.54);
}

.addressWithEditButton button {
  position: absolute;
  top: 50%;
  line-height: 1.75rem;
  height: 42px;
  right: 6px;
  transform: translateY(-50%);    
}

.formattedAddress {
  text-align: left;  
  font-size: 1rem;
  border-radius: 3px;
  color: #000;  
  background-color: #fff;    
  padding: 25px 5rem 10px 1rem;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* AddressForm component */

.addressInputTriple { 
  display: flex;
  flex-wrap: wrap;
}

.addressInputTriple :global .MuiFormControl-root.MuiTextField-root {
  flex: 1;
  margin-right: 1rem;
} 

.addressInputTriple :global .MuiFormControl-root.MuiTextField-root:first-of-type {
  flex: 1.75;    
}

.addressInputTriple :global .MuiFormControl-root.MuiTextField-root:last-of-type {
  margin-right: 0;
}

.formButtonWrap {
  text-align: right;    
}

.formButtonWrap button {
  min-width: 104px; /* match grid */  
  margin-left: 1rem;    
}

@media screen and (max-width:480px) { 
  .addressInputTriple :global .MuiFormControl-root.MuiTextField-root:first-of-type { 
    flex-basis: 100%;
    margin-right: 0;    
  }    
}

/* AddressInputSection component */
.sectionWrap {
  border-radius: .5rem;
  background-color: #F4f4f4;
  padding: 3rem;
  text-align: center;
}
.addressPrompt {
  font-size: 1.75rem;
  margin: 0 0 2rem;
}
.addressPrompt i {
  font-weight: 700;
}
.addressInput {
  display:inline-block;
}
.sectionWrap :global .MuiFilledInput-root .MuiFilledInput-input {
  background-color: #fff;
}

@media screen and (max-width:600px) { 
  .sectionWrap {
    padding: 1.5rem 0 1rem;
  }
  .addressPrompt {
    font-size: 1.25rem;
    padding: 0 1rem;
    margin: 0 0 1rem;
  }
  .addressInput {
    display: block;
    margin: 0 1rem;
  } 
}
