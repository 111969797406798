/**
 * Material UI TextField overrides
 * See API: https://material-ui.com/api/text-field/
 */

/* Input- sizing, padding and margin defaults */

/* Login on white BG */

:global #loginEmail { background-color: rgb(236, 236, 236); }
:global #loginEmail:active,
:global #loginEmail:hover { background-color: rgb(246, 246, 246); }

:global #loginPassword { background-color: rgb(236, 236, 236); }
:global #loginPassword:active,
:global #loginPassword:hover { background-color: rgb(246, 246, 246); }

/* New User on green BG */

:global .MuiFormControl-root.MuiTextField-root { 
  width: 100%;
  margin: 0 0 1rem;
}

:global .MuiFilledInput-root .MuiFilledInput-input { 
  font-size: 1rem; 
  border-radius: 3px;
  padding: 25px 1rem 10px; 
  background-color: #edf7f3; 
}

/* onFocus overrides - using white as opposed to gray since we're on a green background */
:global .MuiFilledInput-root:hover .MuiFilledInput-input { background-color: #fff; }
:global .Mui-focused .MuiFilledInput-input { background-color: #fff;}
:global .MuiFormLabel-root.Mui-focused {
  color:rgba(0, 0, 0, 0.54) !important;
}  

/* Tweak alignment of the animated form label */
:global .MuiTextField-root .MuiInputLabel-filled {
  transform: translate(16px, 26px) scale(1);
  font-size: 1rem;
}

:global .MuiTextField-root .MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(16px, 10px) scale(0.75);
}

/* Validation error message styling - white text etc. */
:global .MuiFormControl-root .MuiFormHelperText-root.Mui-error {
  color: #fff;
  margin: 0;
  padding: .2rem .8rem;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

:global .MuiFormControl-root .MuiFormHelperText-root.Mui-error .MuiSvgIcon-root {
  vertical-align: middle;
}
  
:global .MuiFormControl-root .MuiFormHelperText-root.Mui-error span {
  font-size: .8rem;
  vertical-align: middle;
  line-height: 24px;
  margin: 0 .5rem;
}

@media screen and (max-width: 480px) {
  :global .MuiFilledInput-root .MuiFilledInput-input,
  :global .MuiTextField-root .MuiInputLabel-filled { 
    font-size: 1.125rem;
  }
}