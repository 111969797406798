.wrapper {
  margin-top: 2rem;  
  background-color: #54a40a33;
  padding: 1.375rem;
  border-radius: .5rem;    
}

.label {
  text-align: center;
  font: var(--zrFont);
  font-size: 1.5rem;
  color: #396f07;
  padding: .5rem;
  margin: 0 0 .5rem;
}

.wrapper :global .MuiLinearProgress-root {
  height: 8px;
  border-radius: 4px;
}

/* the bar */
:global .MuiLinearProgress-root.MuiLinearProgress-colorPrimary { 
  background-color: #bcbcbc;
}

/* the indicator */
:global .MuiLinearProgress-root .MuiLinearProgress-barColorPrimary {
  border-radius: .5rem;   
  background-color: var(--zrGreen);
}