.emptyBag {
  position: fixed;
  color: #9B9B9B;
  text-align: center;
  line-height: 1.25rem;
}

.emptyBag.inMenu {
  width: 100%;  
  margin-left: -10px;
}

.emptyBag.inLayout {
  top: 106px;
  margin-left: 81px;
}

.bagImage {
  display: inline-block;
  max-width: 100%;
  max-height: 150px;
  margin: 0 0 1rem; 
}
.bagLogo {
  position: absolute;
  top: 6rem;
  left: 50%;
  max-width: 18%;
  transform: translateX(-50%);
}

.inMenu .bagImage {
  margin-top: 3rem;  
}

@media screen and (max-width: 480px) {
  .emptyBag.inMenu {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -10px;
  }
}