/* PlaceOrder / CheckOut */

.placeOrderGrid {
  display: flex;
}

.placeOrderCol {
  flex:4;
}

.dispensaryColLayout {
  box-sizing: border-box;
  padding: 0 2rem;
  max-width: 1364px;
  margin: 0 auto;
}

.cartCol {
  border-left: 1px solid #e7e7e7;
  flex: 0 0 339px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  position: relative;
  z-index: 1; /* for modal stacking */
}

.placeOrderWrapper {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 1rem;    
}

.logoWrap {
  display:flex;
  align-items: center;    
}

.logo {
  margin-right: .5rem;   
  padding: .25rem;
  height: 3.5rem;
  max-width: 33%; /* funky test logos */
  border: 2px solid #666;
  border-radius: .5rem;    
}

.dispensaryName {
  flex: 1;
  font-weight: 700;    
}

.orderFrom {
  line-height: .75rem;  
  font-size:.75rem;
  color: #767676;
}

.orderStore {
  line-height: 1.5rem;
  font: var(--zrHeaderFont);  
  font-size: 1.5rem;  
  font-weight: 800;
  color: var(--zrBlack);    
}

.orderStore:hover {
  text-decoration: underline;
  cursor: pointer;
}

.section {
  display: flex;
  padding: 1rem;
  border-bottom: 1px solid #e6e7e8;  
}

.section:last-of-type {
  border:none;
}

.sectionTitle {
  flex: 0 1 160px;
  font-weight: 700;
  margin: 0 0 1rem;    
}

.sectionInner {
  flex: 1;
  font-weight: 500;  
  color: #555;    
}

.sectionInner label,
.sectionInner input[type="radio"] {
  margin: 0 .5rem 0 0;    
}

.disabled {
  color: #c0c0c0;
}

.radioWrapper {
  display: flex;
  margin-bottom: .5rem;
}
.radioWrapper input[type="radio"] {
  margin: .2rem .5rem 0 0;
}

.radioWrapper input,
.radioWrapper label {
  cursor: pointer;
}

.cartItem { 
  display: flex;
  justify-content: left;
  margin: 0 0 .25rem;
}

.itemCount,
.itemPrice {
  flex: 1;
}

.itemTitle {
  flex: 10;    
}

.itemPrice {
  text-align: right;    
}

.removeFromOrder {
  font-size: .75rem;
  color: var(--zrGreen);
  margin-left: .5rem;
  vertical-align: 4px;
  cursor: pointer;
}

@media screen and (max-width:800px) {
  .section { 
    display: block;
  }
}

/* Inline Sign In/Sign Up - SignInOrSignUp.jsx */
.signIn { 
  width: 500px;
  max-width: 90vw;  
  margin: 2rem auto;    
}

.passwordResetLink {
  cursor: pointer;
  margin: 1.25rem 0 2rem;
  text-align: center;
  text-decoration: underline;
  font-style: italic;
  font-size: 1.125rem;
}

.buttonWrap button {
  padding: 1rem 3rem;
  min-width: 64%;
}

.signUpLink {
  cursor: pointer;  
  box-sizing: border-box;
  padding: 10px 2rem;
  border: 2px solid var(--zrGreen);
  margin: 3rem auto;
  color: var(--zrGreen);
  font-weight: 800;
  font-size: 1.25rem;
  text-align: center;
  border-radius: .25rem;
  transition: color, background-color .25s ease-in-out;
}

.signUpLink:hover {
  background-color: var(--zrGreen);
  color: #fff;
}

@media screen and (max-width:480px) {
  .buttonWrap button {
    min-width: 100%;
  }      
}
