.productCardWrap {
  flex: 0;
  flex-basis: calc(50% - 4px); /* for space-between */
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.productCard {
  position: relative;  
  flex: 1;
  box-sizing: border-box;
  min-height: 165px; /* min height given product image aspect ratio and column widths */
  max-height: 368px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .5rem;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
}

.productCard:hover {
  border: 1px solid #d6d6d6;
  /* TODO pick one */
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2); /*, 0 4px 5px 0 rgba(0,0,0,0.14); /*, 0 1px 10px 0 rgba(0,0,0,0.12); */
  box-shadow: 0 3px 5px #b2b2b2;
}

.productBody {
  flex: 6;
  position: relative;
  padding: .375rem .75rem .75rem;
}

.productImageWrap {
  position: relative;  
  flex: 4; 
}

.productImage {
  display: block;
  margin:.5rem auto; 
  border-radius: .25rem; 
  max-width: calc(100% - 1rem);
  height: 175px;    
}

.staffPickWrap {
  margin: 0 0 .75rem;
}

.productName {
  color: var(--zrBlack);
  font: var(--zrHeaderFont);
  font-size: .875rem;
  font-weight: 800;
  margin:0 0 .5rem;
  text-decoration: none;
  composes: twoLines from '../common/mixins.css'; 
} 

.productInfo {
  margin: .5rem 0 .625rem;
}

.brand {
  color: #000;
  font-size: .75rem;
  max-width: 220px;
  margin: .5rem 0;
  composes: ellipses from '../common/mixins.css'; 
}

.productDesc {
  margin: 0 0 1.5rem;
}

.productPrice {
  font-size: .875rem;
  font-weight: 700;
  color: var(--zrTextGray); /* #9b9b9b fails contrast */
}

.discountIcon {
  vertical-align: -3px;
  margin-left: 4px;
  margin-right: 4px;
}

.staffPick {
  color: #fff;
  background-color: var(--zrDarkBlue);
  padding: .125rem .5rem;
  border-radius: 10px;
  font: var(--zrFont);
  font-weight: 600;
  font-size: .675rem;
  vertical-align: text-bottom;
  margin-left: .25rem;
}

.orderQuantity {
  color: var(--zrGreen);
  font-weight: 700;
  margin: 0 .5rem;
}

.addedWrapper {
  font-weight: normal;
  position: absolute;
  z-index: 0; /* so as not to overlap sticky category filter */
  top: .375rem;
  right: .375rem;
}

@media screen and (max-width: 800px) {
  .productCard {
    min-height: 140px;
  }
  .productBody {
    flex: 8;
    padding-right: 0; /* image covers gutter */
    padding-bottom: 36px;
  }
}

@media screen and (max-width:600px) {
  .productBody {
    flex: 10;
  }
  .productName {
    font-size: 1rem;
  }  
}

/* TODO: pull price out of grid for mobile */
@media screen and (max-width:480px) {
  .productBody {
    flex: 8;
    padding-right: 0; /* image covers gutter */
  }
}  