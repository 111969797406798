.apButton {
  cursor: pointer;
  display: block;
  width: 400px;
  max-width: 100%;
  margin: 2rem auto 1rem; 
}

.widgetMessage {
  max-width: 500px;
  margin: 0 auto;
}

.accountMessage {
  color: #555;
  text-align: center;
}

.accountMessage sup {
  margin: 0 .125rem;
  font-size: 1.125rem;  
  font-weight: 600;
  vertical-align: 0;    
}

:global #container-fastlink {
  max-width: 600px; /* widget layout seems to be optimized for 600px width */
  margin: 2rem auto;
  border: 3px solid #ccc;
  border-radius: .5rem;
}

:global #container-fastlink iframe {
  border-radius: .5rem;
}

@media screen and (max-width: 480px) {
  :global #container-fastlink {
    margin: 0 -1.5rem; 
  }
}  