.adjustQuantity {
  margin: 0 2rem 0 0;  
  display: flex;
  justify-content: flex-end;
}

.control {
  cursor: pointer;    
}

.control,
.countWrap {
  flex: 0;
  line-height: 34px;    
}

.control svg {
  vertical-align: middle;    
}

.count  {
  min-width: 72px;
  box-sizing: border-box;
  margin: 0 1rem;
  padding: .25rem;
  border-radius: .25rem;
  background-color: #edf0f2;
  text-align: center;
  font-weight: 700;
}

@media screen and (max-width:800px) {
  .adjustQuantity { 
    margin: 1rem 1rem 1rem 0;  
  }  

  .count  {
    min-width: 42px;
    box-sizing: border-box;
    margin: 0 .5rem;
  }
}