
.slideInCart {
  box-sizing: border-box;
  color: #000;  
  position: fixed;
  z-index: 4; /* over hamburger icon */
  text-align: left;
  top: 0;
  right: 0; 
  bottom: 0; 
  width: 500px;
  max-width: 100%;
  padding-top: 3rem;
  transform: translateX(+105%);
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.slideInCart.open {
  transform: translateX(0);
}

.cropShadow {
  background-color: #fff;  
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5%;
  right: 0;  
  overflow: hidden;
  box-shadow: 0 0 24px #ccc; 
}

.closeIcon {
  margin: 12px 6px;
  cursor: pointer;  
  border: none;
  background-color: inherit;    
}

.iconWrap {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.cartTotal {
  font-weight: 600;
  font-size: 1.125rem;
  margin-right: 0.75rem;
}

.closeIcon .closeX {
  height: 1rem;
}

.closeIcon .closeText {
  font-weight: 600;
  font-size: 1.125rem;
  margin: 0 0.5rem;
  vertical-align: 1px;
}

@media (max-width:480px) {
  .slideInCart {
    width: 100%;
  }
  .cropShadow {
    left: 0;
  }            
}
