/** HEADERS */
/* Do not add Top Margin to headers! */
.header {
  font: var(--zrHeaderFont);
  font-size: 2rem;
  margin: 0 0 1.25rem;
}
  
.header.large  {
  font-size: 2rem;
  margin: 0 0 1.25rem;
}

.header.medium {
  font-size: 1.5rem;
  margin: 0 0 1rem;
}

.header.small {
  font-size: 1.25rem;
  margin: 0 0 .75rem;    
}

.header.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* tablet sizing at 800 */
@media screen and (max-width: 800px) {
  .header.large  {
    font-size: 1.75rem;
  } 
}

/* start mobile sizing at 600 */
@media screen and (max-width: 600px) {
  .header.large {
    font-size: 1.5rem;
  } 
  
  .header.medium {
    font-size: 1.125rem;   /* 1.1875 = 19px */
  }
  
  .header.small {
    font-size: 1.125rem;
    margin: 0 0 .25rem;    
  }  
}
  