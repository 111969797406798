.cartItem {
  position: relative;
  margin: 0 0 .5rem;
  border-bottom: 1px solid #e6e7e8;
}

.itemDetails {
  display: flex;    
  font-size: .8125rem;
  margin: 0 0 .5rem;
}

.itemTitle {
  flex:10;    
}

.itemCount,
.itemPrice {
  flex:1;    
}

.itemPrice {
  text-align:right;    
}

.itemCount {
  min-width: 28px;    
}

.itemRemoveWrap {
  position: relative;
  z-index: 2; /* stack over product image area */
}

.itemRemove {
  cursor: pointer;  
  color: var(--zrGreen);
  position: absolute;
  top: -2px;
  font-size: .8125rem;
  font-weight: 700;   
}

.itemImg {
  display: block;  
  max-width: 100%;
  max-height: 80px;
  border-radius: .5rem;
  margin: 0 auto .5rem;    
}

.itemDetail {
  display: flex;
  align-items: flex-end;
  padding-bottom: 3px;
  border-bottom: 1px solid #608609;    
}

.imageWrap {
  position: relative;
}

.imageWrap:hover .itemControl {
  opacity: 1;  
}

.itemControl {
  position: absolute;
  cursor: pointer;
  color: #fff;
  background: var(--zrGreen);
  opacity: .6;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 26px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 23px;
  font-weight: 700;
  margin: 0 3px 0 0;
  transform: translateY(-50%);   
}

.increase { top: 50%; right: 2rem;}
.decrease { top: 50%; left: 2rem;}

.notAvailableWrap {
  position: relative;
  text-align: center; 
  margin: 1rem;
  padding: .5rem;
  background-color: #fff;
  border-radius: .25rem;
}

.notAvailableMsg {
  margin: 0 0 .5rem;
}

.notAvailableMask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #54a40a99;
  border-radius: .25rem;
}

