/* The Outer Reg Flow wrapper */
.inlineRegFlow {
  max-width: 100%;
  box-sizing: border-box;
}

.inlineRegFlowGuest {
  width: auto;
  padding: 1.5rem 3rem;
  background-color: #f6f7f8;
  border-radius: 0.5rem;
}

/* Sign In etc. */
.narrowForm {
  max-width: 400px;
  padding-top: 1rem;
  margin: 0 auto;
}

.hdrPair {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1.25rem;
}
.pairLabel {
  font-size: 1.125rem;
  font-weight: 700;
}

.titleWrap {
  font-size: .875rem;
}

.hdrPairLft {
  flex: 1;
  font-size: 1rem;
  font-weight: 700;
  color: #000;  
}
.hdrPairRgt {
  flex: 0;
  white-space: nowrap;
}
.hdrLink {
  color: var(--zrGreen);
  cursor: pointer;
  font-size: .875rem;
  font-weight: 700;
  margin-left: .5rem;
  border: 2px solid var(--zrGreen);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  white-space: nowrap;
}
.hdrLink:hover {
  color: white;
  background-color: var(--zrGreen);
}
.titleText {
  font-size: 1rem;
  font-weight: 700;
  color: #000;   
}  

.secondaryLink {
  cursor: pointer;
  font-weight: 700;
  color: var(--zrGreen);
}  
.secondaryLink:hover {
  text-decoration: underline;
}

.registeredUserDetail {
  margin: 0 0 .75rem;
}

.registeredUserDetail:last-of-type {
  margin: 0;
}

.registeredUserDetail .detailLabel {
  color: #000;
  font-weight: 700;
  margin-right: .5rem;
}

.signInText {
  white-space: nowrap;
}

.detourPrompt,
.signInPrompt {
  white-space: nowrap;
  margin-right: .5rem;
}

.signInLink {
  color: var(--zrGreen);
  cursor: pointer;
  font-size: .875rem;
  font-weight: 700;
  margin-left: .5rem;
  border: 2px solid var(--zrGreen);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  white-space: nowrap;
}

.signInLink:hover {
  color: white;
  background-color: var(--zrGreen);
}
.formCancelLink {
  margin-top: 1.5rem;
}
.inlineRegFlow :global #loginEmail {
  background-color: white;
}
.inlineRegFlow :global #loginPassword {
  background-color: white;
}

.inlineRegFlow.inlineRegFlowGuest :global  .MuiFilledInput-root .MuiFilledInput-input {
  background-color: white;
}  

.inlineRegFlow.inlineRegFlowGuest :global .PhoneInputInput {
  background-color: white;
}

.inlineRegFlowGuest .inputPair :global .MuiTextField-root:first-child {
  flex: 1 1 50%;
}

.inlineRegFlowGuest .inputPair .inlinePhoneInput {
  flex: 1 1 50%;
}

@media screen and (max-width:600px) {
  .hdrPair {
    margin: 0 0 .75rem;
  }
  .signInPrompt {
    font-size: .875rem;
  }
  .signInLink {
    display: inline-block;
    margin-top: 0.25rem;
  } 
  .signInText {
    white-space: normal;
    text-align: right;
  }
  .signUpLink {
    margin-top: 1rem;
  } 
}

@media screen and (max-width:480px) {
  .inlineRegFlowGuest {
    padding: 1rem;
  }
}

/* birthDate + phone */
.inputPair {
  display: flex;
}

.inputPair :global .MuiTextField-root:first-child {
  margin-right: 1rem;
}

.inlineRegFlow  :global .PhoneInput {
  position: relative;
}

/* Phone number country select */
.inlineRegFlow :global .PhoneInputCountrySelect {
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 8px;
  width: 65px;
  padding: 10px;
  opacity: 0;
  cursor: pointer;
}

.inlineRegFlow :global .PhoneInputCountrySelectArrow {
  top: 14px;
  right: 15px;
  position: absolute;
  height: 20px;
  width: 20px;
}

.inlineRegFlow :global .PhoneInputCountrySelectArrow:before {
  position: absolute;
  top: 0;
  right: 0;
  content: '\25B2';
  font-size: 14px;
  color: #999;
}

.inlineRegFlow :global .PhoneInputCountrySelectArrow:after {
  position: absolute;
  top: 14px;
  right: 0;
  content: '\25BC';
  font-size: 14px;
  color: #999;
}

.inlineRegFlow :global .PhoneInputCountryIconImg {
  padding: 8px 23px 8px 8px;
  right: 8px;
  position: absolute;
  height: 20px;
  top: 8px;
  border: 1px solid #ccc;
}

@media screen and (max-width:600px) {
  /* apartment + birthDate */
  .inputPair {
    display: block;
  }
  .inputPair :global .MuiTextField-root:first-child {
    margin-right: 0;
  }
}

/* phone input  - style like the Material UI inputs */
.phoneInputWrapper {
  position: relative;
  margin: 0 0 1rem;
}

.inlineRegFlow :global .PhoneInputInput {
  font-size: 1rem;
  border-radius: 3px 3px 0 0;
  background-color: rgba(0,0,0,.05);
  width: 100%;
  padding: 24px 1rem 10px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42); 
}
  
.inlineRegFlow :global .PhoneInput::after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #ccc;
  pointer-events: none;
}

.phoneInputError :global .PhoneInputInput {
  color: var(--zrTextRed);
  border-bottom: 2px solid var(--zrTextRed);  
}

.phoneInputError :global .PhoneInputInput:focus {
  color: #000;
  border-bottom: 2px solid var(--zrTextRed);
}

.inlineRegFlow :global .PhoneInputInput:focus {
  background-color: #fff;
  outline: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}

.inlineRegFlow :global .MuiFilledInput-underline:after {
  border-color: rgba(0, 0, 0, 0.87);
}

/* MUI Overrides */

.inlineRegFlow :global .MuiFilledInput-root.Mui-focused,
.inlineRegFlow :global .MuiFilledInput-root:hover,
.inlineRegFlow :global .MuiFilledInput-root .MuiFilledInput-input {
  background-color: transparent;
}

.inlineRegFlow :global .MuiFormControlLabel-root {
  align-items: flex-start;
}
.inlineRegFlow :global .MuiTypography-body1 {
  font-size: .875rem;
  padding-top: .675rem;
}

/* Error messages */
.inputError,
.termsError,
.phoneNumberErrorMsg {
  display: flex;
  line-height: 133%;
  color: var(--zrTextRed);
  font-size: .8rem;
}

.buttonError {
  display: inline-flex;
}

.inputError svg,
.termsError svg,
.phoneNumberErrorMsg svg {
  flex: 0 0 18px;
}

.inputError span,
.termsError span,
.phoneNumberErrorMsg span {
  flex: 1;
  margin: 0 0 0 5px !important;
  padding: .25rem 0;
  line-height: 133%;
  text-align: left; 
}

.inlineRegFlow :global .MuiFormControl-root .MuiFormHelperText-root.Mui-error {
  display: flex;
  padding: 0;
  line-height: 133%;
  color: var(--zrTextRed); 
}    

.inlineRegFlow :global .MuiFormControl-root .MuiFormHelperText-root.Mui-error span {
  flex: 1;
  margin: 0 0 0 5px !important;
  padding: .25rem 0;
  line-height: 133%;
}

.inlineRegFlow :global .MuiFormControl-root .MuiFormHelperText-root.Mui-error svg {
  flex: 0 0 18px;
}
