/* for positioning images */
.imageOuter,
.imageWrap {
  position: relative;
}

.productImage {
  display: block;
  margin: 1rem auto .5rem; 
  border-radius: .25rem; 
  max-width: calc(100% - 1rem);
  height: 150px;    
  max-height: 100%;
}

/* image coming soon overlay */
.placeholderMessage {
  position: absolute;
  top: 82%;
  left: 8%;
  right: 8%;
  transform: translateY(-50%);
  border-radius: 24px;
  text-align: center;
  font-size: .75rem;
  font-weight: 600;
  line-height: 2rem;
  overflow: hidden;
}