.cart {
  top: 3rem;
  right: 0;
  position: fixed;
  bottom: 0;
  overflow-y: auto;
  width: calc(100% - 25px); /* box shadow */
}

.cartInner {
  padding: 0 1rem; /* need space for scroll bar on right */
  background-color: #fff;
  margin-bottom: 12rem;  /* better scroll-into-view */ 
}

.cartHeader {
  position: sticky;
  top: 0;
  z-index: 3; /* 3 due to cartItem controls */  
  padding: 1rem 0;
  background-color: #fff;
  font-size: 1.25rem;
  font-weight: 900;
  line-height: 1.5rem;
  color: #191919;
}

.cartDetails { 
  display: flex;
  align-items: center;
  background-color: var(--menuGray);
  font-size: 1rem;
}

.dispensaryDetail {
  flex: 1;
  padding: .5rem 1rem;
}

.shoppingAt {
  font-size: .75rem;
  color: var(--tipTextGray);
}

.orderTypeDetail {
  flex: 0;
  padding: .5rem 1rem;
}

.dispensaryName {
  font: var(--zrHeaderFont);
  font-size: 1rem;
}

.cartItems {
  margin: 1rem 0;
  padding: 0;  
  list-style-type: none;
}

.disabledMsg {
  font-weight: 600;
  font-size: .875rem;
  text-align: center;
  padding-top: 2px;
  color: #444;
}

.disabledMsg button {
  margin-top: 0;
}

.disabledMsg sup {
  vertical-align: -2px;
  line-height: 1rem;
  font-size: 1.2rem;
  padding-right: .125rem;
}

/* OrderPickupMap.jsx */ 

.pickupMapWrapper {
  margin: .5rem 0 1rem;
  text-align: center;
}


.pickupMapBox {
  margin-top: .5rem;
  border: 2px solid #d7d7d7;
  cursor: pointer;
}

.pickupMapBox .mapAddress {
  text-align: left;
  padding:.5rem 1rem;
  line-height: 110%;
  font-size: 1rem;
  font-weight: 900;
}

.pickupMapBox .addressLabel {
  font-size: .75rem;
  line-height: 1.5rem;
  color: var(--tipTextGray);
}

.mapImage {
  display: block;
  max-width: 100%;
  min-height: 175px;
}

.addressWarning {
  position: relative;
  padding-left: 2rem;
  line-height: 133%;
}

.addressWarning svg {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--zrGreen);
}

.addressModalLink {
  cursor: pointer;
  color: var(--zrGreen);
  font-weight: 700;
  text-decoration: underline;
}

@media screen and (max-width:480px) {
  .cart {
    width: 100%;
  }
  .cartHeader {
    padding-top: 0;
  }  
  .cartInner {
    padding: .5rem 1rem 6rem;
  }
}