/* Related components: e.g. styles used in the modal view: SelectTimeSlotPreview.jsx */

.timeslotWrap {
  min-height: 200px;
}

.selectedSlotLabel {
  font-weight: 700;
  color: #000;
  margin: 0 0 1rem;
}

/* DisplayPickupSlotsModal */
.dispensaryLogo {
  height: 64px;
  display: block;
  margin: 0 auto .5rem;    
}

.dispensaryName {
  font: var(--zrHeaderFont);    
  font-weight: 700;
  font-size: 1.25rem;
  text-align: center;
  margin:0 0 .5rem;
  color: #000;  
}

.subHeader {
  font: var(--zrHeaderFont);
  font-size: 1rem;    
  font-weight: 700;
  margin: 1rem 0 0;  
  padding: .5rem 0;
  border-top: 1px solid #e6e7e8;    
}

/* end related components */

.fldSet {
  border: none;
  position: relative;
  padding: 0;
  margin: 0;    
}

.fldSet input {
  position: absolute;
  left: .5rem;
  top: 50%;
  transform: translateY(-50%);
}

.fldSet label {
  cursor: pointer;  
  display: block;
  padding: .75rem .5rem .75rem 2rem;    
}

.fldSet em,
.fldSet label span.chooseButton {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 4px 8px;
  color: var(--zrGreen); 
  font-size: .875rem;
  font-weight: 800;
  text-transform: uppercase;
  border: 2px solid var(--zrGreen);
  border-radius: .25rem;
  min-width: 6rem;
  text-align: center;   
}

.fldSet label span.notAvailable {
  display: none;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 4px 8px;
  font-weight: 500;
  text-align: center;   
}

.fldSet.selected {
  background-color:#ededed;    
}

/* Disabled time slot styles */
.fldSet.disabledFldSet {
  opacity: .8;
}
.fldSet.disabledFldSet label {
  cursor: default;       
}
.fldSet.disabledFldSet label:hover {
  background-color: inherit;    
}
.fldSet.disabledFldSet label span.chooseButton {
  display: none;    
}
.fldSet.disabledFldSet label span.notAvailable {
  display: block;    
} 

.fldSet em {
  display: none;
  z-index: 1;
  font-style: normal;    
  color: #fff;
  background-color: var(--zrGreen);
}

.fldSet:hover {
  background-color:#ededed;
}

.fldSet.selected em {
  display: block;
}    
