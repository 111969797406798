/** HEADERS */

.primaryHeader {
  font: var(--zrHeaderFont);
  font-size: 2rem;
  margin: 0 0 1.25rem;
}

.secondaryHeader {
  font: var(--zrHeaderFont);
  font-size: 1.75rem;
  margin: 0 0 1rem;
}

.mediumHeader {
  font: var(--zrHeaderFont);
  font-size: 1.5rem;
  margin: 0 0 1rem;
}

/* unused: replace XLargeHeader with this */
.primaryHeader.largeHeader {
  font-size: 2rem;
}

.regStyleHeader {
  color: #4d4d4d;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
}

.successMessage,
.errorMessage {
  display: inline-block;
  position: relative;
  margin: 1rem 0 0;
  padding: .5rem .5rem .5rem 2rem;
  line-height: 133%;
  border-radius: .25rem;
}

.successMessage {
  color: var(--zrGreen);
  background-color: #fff;
  font-weight: 600;
}

.errorMessage {
  font-weight: 500;
  font-size: .875rem;
  color: #000;
  background-color: #fbe6e6; 
}

.simpleErrorMessage {
  align-content: center;
  font-weight: 500;
  font-size: 1.125rem;
  color: var(--zrTextRed);
  margin: 1rem 0;
}  

.simpleErrorMessage .icon {
  flex: 0 0 24px;
  margin-right: .25rem;
}

.simpleErrorMessage .text {
  flex: 1;
  text-align: left;
  padding-top: 2px;
}  

.errorMessage.errorMessageBanner {
  padding: 1.25rem 1.5rem 1.5rem 4rem;
  font-size: 1.125rem;
  line-height: 133%;
  border-radius: .5rem;
}

.successMessage img {
  position: absolute;
  left: 8px;
  top: 10px;
}

.errorMessage svg {
  position: absolute;
  left: 8px;
  top: .5rem;
}

.errorMessage.errorMessageBanner svg {
  top: 1rem;
  left: 1rem;
  color: var(--zrTextRed);
  font-Size: 2rem;
}

/* tablet sizing at 800 */
@media screen and (max-width: 800px) {
  .primaryHeader {
    font-size: 1.5rem;
  } 
}

/* start mobile sizing at 600 */
@media screen and (max-width: 600px) {
  .secondaryHeader {
    font-size: 1.125rem;
  }

  .mediumHeader {
    font-size: 1.125rem;
  }

  .regStyleHeader {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .errorMessage.errorMessageBanner {
    padding: .875rem 1rem .875rem 3rem;
    font-size: 1rem;
  }
  
  .errorMessage.errorMessageBanner svg {
    top: .75rem;
    left: .75rem;
    font-Size: 1.5rem;
  } 
}

.xLargeHeader {
  font: var(--zrHeaderFont);
  font-size: 2.25rem;
  margin: 0 0 1.5rem;
}

/* start mobile sizing at 600 */
@media screen and (max-width: 600px) {
  .xLargeHeader {
    font-size: 1.75rem;
  }
  .primaryHeader.largeHeader {
    font-size: 1.5rem;
  } 
}

/* StatusPill */
.statusPill {
  font: var(--zrFont);
  font-weight: 600;
  font-size: .875rem;
  position: absolute;
  z-index: 1;
  padding: 2px 9px;
  border-radius: .75rem;
  background-color: var(--zrOrange);
  color: #000;
}