/* AddedToCartCheck: Check mark modal  */
.addedToCartCheck {
  position: fixed;
  z-index:1;
  display: inline-block; /* when not fixed */
  vertical-align: middle;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: .75rem;
  text-align: center;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 1rem;
  /* same as scrolling nav */
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);;  
}

.addedToCartCheck svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: .75rem;    
}

.addedToCartCheck .text {
  color: #000;
  line-height: 20px;
}

.addedToCartCheck  .addlMessage {
  font: var(--zrFont);
  font-size: 1rem;
  font-weight: 400;
  padding: 1rem 0 .5rem;
}

.addedToCartCheck  .addlMessageHdr {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 .5rem;
}

.addedToCartCheck .addlMessageInner {
  padding: 0 .5rem;
}

/* InCartCheck: Check mark in product card */
.inCartCheck {
  display: flex;
  flex-direction: row;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--zrOrange);
  border-radius: 50%;
  height: 2rem;
  min-width: 2rem;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px;
}

.inCartCheck .text {
  display: inline-block;
  margin-top: -1px;
  line-height: 2rem;
  font-size: .875rem;
  font-weight: 700;
  color: var(--zrDarkBlue);
}

.quickAdd {
  position: relative;
  bottom: 0px;
  background: rgb(255, 255, 255);
  color: #000;
  border-radius: 48px;
  display: flex;
  align-items: flex-end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  flex-direction: row;
  overflow: hidden;
  cursor: default;
  right: auto;
  margin-bottom: 16px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px;
}

.quickAddButton {
  position: relative;
  max-width: 100%;
  margin: 0px;
  display: inline-flex;
  min-height: 32px;
  border-radius: 100%;
  border: none;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  text-align: center;
  background-color: rgb(255, 255, 255);
  color: rgb(25, 25, 25);
  height: 32px;
  width: 32px;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.quickAddBarItems {
  display: flex;
  box-sizing: border-box;
  background-color: #fff;
  width: 124px;
  border-radius: 1rem;
  align-items: center;
  justify-content: space-between;
  font-size: .875rem;
  line-height: 1rem;
  font-weight: 600;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px;
}

.barIncrement,
.barDecrement {
  flex: 0;
}

.mobileClose,
.barIncrement,
.barDecrement,
.barCount {
  padding: 6px;
  color: #000;
}

.mobileClose {
  display: none;
  padding-right: 12px;
}

/* x1 */
.tinyX {
  font-size: .675rem;
  vertical-align: 1px;
  margin-right: 1px;
}

@media screen and (max-width: 480px) {
  .quickAddButton {
    min-height: 1.5rem;
    height: 1.5rem;
    width: 1.5rem;
  }  
  .quickAddBarItems {
    width: 148px;
  }
  .transitionBar {
    width: 124px;
  }
  .mobileClose {
    display: block;
  }
}