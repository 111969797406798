.wrap404 {
  color: var(--zrGreen);
  font-size: 2.5rem;
  line-height: 3rem;
  padding: 3rem 0;
  text-align: center;
}

.notFoundMsg {
  margin: 2rem 0;
  font-size: 1.25rem;
  line-height: 133%;
}