.toCheckoutForm {
  margin: 0 0 3rem;    
}

.toCheckoutForm sup {
  vertical-align: text-top;
}

.checkoutDetail {
  display: flex;
  margin: 0 0 .25rem;
  font-weight: 400;    
}

.checkoutTotal {
  display: flex;
  font-weight: 700;
  margin: .5rem 0;
  padding-top: .25rem;    
  border-top: 1px solid #608609;
}

.checkoutTotal span.label,
.checkoutDetail span.label {
  flex: 1;    
}

.checkoutTotal span.amount,
.checkoutDetail span.amount {
  flex: 0;    
}

/* tooltip */
.checkoutDetail span.label :global .MuiButton-text {
  min-width: auto;
  width: 32px;
  border-radius: 50%;
  padding: 7px 14px 5px;
}

/* TODO: This is an app global (it's a body node) move somewhere else */
:global .MuiTooltip-tooltip {
  box-shadow: 0 0 4px #000c;
}

.noCharge {
  text-decoration: line-through;
  color: #c0c0c0;
}

.priceComment {
  float: none;
  text-decoration: none;
  color: #608609;
  font-weight: 600;
  padding-right: .5rem; 
}

.promotion {  
  margin: 2rem 0 0;
  padding: .5rem 1rem;
  border-radius: .25rem;
  background-color: #c4f992;
  font-weight: 600;
  line-height: 133%;      
}

.buttonWrap {
  max-width: 50%;
  margin: 0 auto;
}

.addlFeeMsg {
  font-weight: 600;
  margin: .5rem 0;
  text-align: center;
  font-style: italic;
  font-size: .875rem;
}

.apButtonWrap {
  text-align: center;
}

.apButton {
  cursor: pointer;
  display: block;
  margin: 2rem auto .5rem;
  width: 400px;
  max-width: 100%;
}

.apMessage {
  color: #555;
  font-weight: 600;
  text-align: center;
}

@media screen and (max-width:480px) {
  .buttonWrap {
    max-width: none;    
  }
}