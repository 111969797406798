.legalWrap {
  margin: .25rem 0 2rem;  
  padding: 0 1rem;
}

.legalHdr {
  font: var(--zrFont);
  font-weight: 600;  
  font-size: 1.5rem;    
}

.sectionHdr {
  font: var(--zrFont);
  font-weight: 600;    
  font-size: 1.125rem;    
}

.subHead {
  font: var(--zrFont);
  font-weight: 600;  
  font-weight: 600;
  margin: 1rem 0;    
}

.legalWrap li {
  margin: .5rem 1.5rem;
  list-style-position: outside;
  list-style-type: disc;
}