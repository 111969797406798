/* The Outer Reg Flow wrapper */
.inlineRegFlow {
  width: 500px;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.cancelLink {
  cursor: pointer;
  color: var(--zrGreen);
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.cancelLink:hover {
  text-decoration: underline;
}

/* email avaialable etc. */
.inputFeedback {
  position: relative;
  height: 0;
  font-size: .75rem;
  z-index: 1;
}  

.inputFeedback .inputSuccess {
  color: #248601; /* darker for readability */
  position: absolute;
  top: .5rem;
  right: 1rem;
}

.inputFeedback .inputFailure {
  color: var(--zrTextRed);
  position: absolute;
  top: .5rem;
  right: 1rem;
}

/* apartment + birthDate */
.inputPair {
  display: flex;
}

.inputPair :global .MuiTextField-root:first-child {
  margin-right: 1rem;
}

/* suppress increment on zip code input */
.inlineRegFlow input[type=number]::-webkit-inner-spin-button, 
.inlineRegFlow input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.inlineRegFlow  :global .PhoneInput {
  position: relative;
}

/* Phone number country select */
.inlineRegFlow :global .PhoneInputCountrySelect {
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 8px;
  width: 65px;
  padding: 10px;
  opacity: 0;
  cursor: pointer;
}

.inlineRegFlow :global .PhoneInputCountrySelectArrow {
  top: 14px;
  right: 15px;
  position: absolute;
  height: 20px;
  width: 20px;
}

.inlineRegFlow :global .PhoneInputCountrySelectArrow:before {
  position: absolute;
  top: 0;
  right: 0;
  content: '\25B2';
  font-size: 14px;
  color: #999;
}

.inlineRegFlow :global .PhoneInputCountrySelectArrow:after {
  position: absolute;
  top: 14px;
  right: 0;
  content: '\25BC';
  font-size: 14px;
  color: #999;
}

.inlineRegFlow :global .PhoneInputCountryIconImg {
  padding: 8px 23px 8px 8px;
  right: 8px;
  position: absolute;
  height: 20px;
  top: 8px;
  border: 1px solid #ccc;
}

@media screen and (max-width:600px) {
  /* apartment + birthDate */
  .inputPair {
    display: block;
  }
  .inputPair :global .MuiTextField-root:first-child {
    margin-right: 0;
  }
}

/* phone input  - style like the Material UI inputs */
.phoneInputWrapper {
  position: relative;
  margin: 0 0 1rem;
}

.inlineRegFlow :global .PhoneInputInput {
  font-size: 1rem;
  border-radius: 3px 3px 0 0;
  background-color: rgba(0,0,0,.05);
  width: 100%;
  padding: 24px 1rem 10px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42); 
}
  
.inlineRegFlow :global .PhoneInput::after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #ccc;
  pointer-events: none;
}

.phoneInputError :global .PhoneInputInput {
  color: var(--zrTextRed);
  border-bottom: 2px solid var(--zrTextRed);  
}

.phoneInputError :global .PhoneInputInput:focus {
  color: #000;
  border-bottom: 2px solid var(--zrTextRed);
}

.inlineRegFlow :global .PhoneInputInput:focus {
  background-color: #fff;
  outline: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}

.inlineRegFlow :global .MuiFilledInput-underline:after {
  border-color: rgba(0, 0, 0, 0.87);
}

/* MUI Overrides */

/* gray background for inputs / white on active/hover */
.inlineRegFlow :global .MuiFilledInput-root {
  background-color: rgba(0,0,0,.05);
}

.inlineRegFlow :global .MuiFilledInput-root.Mui-focused,
.inlineRegFlow :global .MuiFilledInput-root:hover,
.inlineRegFlow :global .MuiFilledInput-root .MuiFilledInput-input {
  background-color: transparent;
}

.inlineRegFlow :global .MuiFormControlLabel-root {
  align-items: flex-start;
}
.inlineRegFlow :global .MuiTypography-body1 {
  font-size: .875rem;
  padding-top: .675rem;
}

/* Error messages */
.inputError,
.termsError,
.phoneNumberErrorMsg {
  display: flex;
  line-height: 133%;
  color: var(--zrTextRed);
  font-size: .8rem;
}

.buttonError {
  display: inline-flex;
}

/* registration submit errors */
.registrationError {
  display: inline-flex;
  align-items: center;
  font-size: 1.125rem;
}

.registrationError svg {
  margin-right: .25rem;
}

.inputError svg,
.termsError svg,
.phoneNumberErrorMsg svg {
  flex: 0 0 18px;
}

.inputError span,
.termsError span,
.phoneNumberErrorMsg span {
  flex: 1;
  margin: 0 0 0 5px !important;
  padding: .25rem 0;
  line-height: 133%;
  text-align: left; 
}

.inlineRegFlow :global .MuiFormControl-root .MuiFormHelperText-root.Mui-error {
  display: flex;
  padding: 0;
  line-height: 133%;
  color: var(--zrTextRed); 
}    

.inlineRegFlow :global .MuiFormControl-root .MuiFormHelperText-root.Mui-error span {
  flex: 1;
  margin: 0 0 0 5px !important;
  padding: .25rem 0;
  line-height: 133%;
}

.inlineRegFlow :global .MuiFormControl-root .MuiFormHelperText-root.Mui-error svg {
  flex: 0 0 18px;
}

/* Terms and Conditions etc. */
.contentLink {
  color: var(--zrDarkBlue);
  text-decoration: underline;
  font-weight: 500;
}

.verifyButton {
  margin: 2rem 0 5rem;
}

/*  SMS versus Voice  */
.verifyMethod {
  text-align: center;
  font-weight: 600;
}

/* RegistrationSuccessMsg */

.successWrap {
  max-width: 500px;
  margin: 0 auto;
}

.successInner {
  padding: 2rem 1rem 1.5rem;
}
.successMsg {
  text-align: center;
  font-size: 1.5rem; 
  font-weight: 700;
  margin-bottom: 2rem; 
}

.exclaim { 
  color: #2da700;
}

.accountTip {
  margin: 2rem -4rem -1rem;
  padding: 1rem .5rem;
  text-align: center;
  font-size: .875rem;
  font-weight: 600;
  color: #fff;
  background-color: var(--zrGreen);
}

.accountTip img {
  display: block;
  margin: .5rem auto 0;
}

@media screen and (max-width:480px) {
  .exclaim {
    display: block;
    margin-bottom: .5rem;
  }
  .accountTip {
    margin: 2rem -1rem -1rem;
  }  
}