.card {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: 2rem auto 8rem;
}

.productImageWrap {
  flex: 0 0 360px;
  border-radius: .25rem;
  margin-right: 3rem;
  overflow: hidden;
}

.productBody {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.productHdr {
  display: flex;
  flex-direction: column;
  min-height: 301px;
}

.productName {
  flex: 0;
  color: var(--zrBlack);
  font: var(--zrHeaderFont);
  font-size: 1.5rem;
  font-weight: 800;
  margin:0 0 .25em;
} 

.brand {
  margin: .5rem 0 .375rem;
}

.weightPillWrapper {
  display: inline-flex;
  margin: .75rem 0;
}

.bulkTipBorder {
  position: relative;
  border: 2px solid var(--zrPromoRed);
  border-radius: .25rem;
  margin-left: .75rem;
}

.bulkTipBorder :global .MuiButton-root:hover {
  background-color: #fff; /* suppress MUI button hover */
}

.bulkTipInner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.productInfo {
  flex: 1; 
  margin: .5rem 0 0;
}

.staffPickWrap {
  margin: .5rem 0;
}

.staffPick {
  color: #fff;
  background-color: var(--zrDarkGreen);
  padding: .125rem .5rem;
  border-radius: 10px;
  font: var(--zrFont);
  font-weight: 600;
  font-size: .675rem;
  vertical-align: text-bottom;
  margin-left: .25rem;
}

.controlsWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid #ccc;
}

.orderQuantity {
  color: var(--zrGreen);
  font-weight: 700;
  margin: 0 .5rem;
}

.addedWrapper {
  font-weight: normal;
  position: relative;
  margin: 0 0 0 1rem;
}

.taxesNote {
  font-size: .875rem;
  font-weight: 500;
  color: #888;
  flex-basis: 100%;
  margin-bottom: 0.25rem;
}

.productDescWrap {
  flex-basis: 100%;
  margin: .75rem 0 0;
  padding: 2rem 2.5rem;;
  background-color:#edf0f2;
  border-radius: .5rem;
}

.productDesc {
  line-height: 140%;
}

.productDescLabel {
  font-weight: 500;
  font-size: 1.25rem;
  margin: 0 0 .5rem;
}

.adjustQuantityWrap,
.productButtonWrap {
  flex: 0;
}

@media (max-width:800px) {
  .productImageWrap {
    flex: 0 0 250px;
    margin-right: 2rem;
  }
  .productHdr {
    min-height: auto;
  }
  .productDesc {
    font-size: 1rem;
  }
}

@media (max-width:600px) {
  .productInfo {
    margin: .75rem 0;
  }

  .productImageWrap {
    flex: none;
  }
  
  .productBody {
    flex: none;
    display: block;
    padding: .75rem 0 0;
    min-height: auto;
  }

  .productDescLabel {
    font-size: .875rem;
  }
  
  .productDesc {
    font-size: .75rem;
  }

  .productButtonWrap {
    flex: 1;
  }
  .productButtonWrap button {
    min-width: 100%;
  }  
}

@media (max-width:600px) {
  .card {
    flex-direction: column;
  }
  .productImageWrap {
    margin-right: 0;
  }
  .controlsWrap {
    margin: 0;
  }
}
