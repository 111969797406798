.infoTiles {
  margin: 0;  
  padding: 0;  
  list-style: none;    
}

.infoTiles li {
  display: inline-block;
  text-align: center;  
  font-weight: 500;
  padding: .3125rem .25rem;
  margin: 0 .5rem 0 0;
  border-radius: .75rem;
  min-width: 54px;
  box-sizing: border-box;
}

.infoTiles li:last-of-type {
  margin-right: 0;    
}

.infoTiles .label {
  font: var(--zrHeaderFont);
  font-size: .5rem;
  font-weight: 800;
  line-height: 150%;    
}

.infoTiles .value {
  font: var(--zrHeaderFont);
  font-size: .8125rem; /* 13px */
  font-weight: 600;
  line-height: 110%;   
}

/* High CBD */
.infoTiles li:last-of-type .value {
  font-size: .75rem;
}

@media screen and (max-width:480px) {
  .infoTiles li {
    min-width: 48px;
  }  
  
  .infoTiles .value {
    font-size: .6875rem;  /* 11px */
  }  
}