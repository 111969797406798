.productCardWrap {
  background-color: #fff;
  border-top: 1px solid #d7e4eb;
  cursor: pointer;
}

.productCardWrap:last-of-type {
  border-bottom: 1px solid #d7e4eb;  
}

.productCard {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  min-height: 150px;
}

.productImageWrap {
  position: relative;
  flex: 0 0 150px;
  min-height: 150px;
}

.productBody {
  flex: 1;
  padding: .75rem 0 .75rem .75rem;
}

.staffPickWrap {
  margin-bottom: .5rem;
}

.brand {
  color: #000;
  font-size: .875rem;
  composes: ellipses from '../common/mixins.css'; 
}

.productName {
  color: var(--zrBlack);
  font: var(--zrHeaderFont);
  font-size: 1.125rem;
  font-weight: 800;
  margin:.5rem 0;
  text-decoration: none;
  composes: twoLines from '../common/mixins.css'; 
} 

.productInfo {
  margin: .5rem 0 0;
}

.productPriceWrap {
  position: relative;
}

.productPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 1.25rem 0 1rem;
  margin-right: .75rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 3rem;
  text-align: center;
  background-color: #eaeff2;
  border-radius: .375rem;
  color: #000; /* #9b9b9b fails contrast */
}

.productWeight {
  font-size: .75rem;
  font-weight: 400;
}

.productWeightDash {
  font-size: .75rem;
  font-weight: 400;
  margin: 0 .25rem
}

.discountIcon {
  margin: -1px 5px 0;
}

.bulkTipWrap {
  margin: .5rem 0 0;
}

.bulkTipWrap button {
  width: calc(100% - 12px);
}

.staffPick {
  color: #fff;
  background-color: var(--zrDarkBlue);
  padding: .125rem .5rem;
  border-radius: 10px;
  font: var(--zrFont);
  font-weight: 600;
  font-size: .675rem;
  vertical-align: text-bottom;
  margin-left: .25rem;
}

.orderQuantity {
  color: var(--zrGreen);
  font-weight: 700;
  margin: 0 .5rem;
}

.addedWrapper {
  font-weight: normal;
  position: absolute;
  z-index: 0; /* so as not to overlap sticky category filter */
  top: -0.75rem;
  right: 0;
}

/* TODO: pull price out of grid for mobile */
@media screen and (max-width:800px) {
  .productBody {
    order: 1;
  }
  .productImageWrap {
    order: 2;
    flex: 0 0 112px;
    min-height: 112px;
  }
  .productPriceWrap {
    order: 3;
    flex-basis: 100%;
  }
  .quickAddWrap {
    display: inline-block;
    position: relative;
  }

  .productPrice {
    display: inline-flex;
    min-width: 148px; /* space for quick add */
    line-height: 2rem;
  }
  .productCardWrap {
    padding: .5rem 0 1.5rem;
  }
}

/* TODO: pull price out of grid for mobile */
@media screen and (max-width:600px) {
  .productCard {
    align-items: flex-start;
  }
  .productImageWrap {
    margin-right: -.75rem; /* right-align */
  }
  .productBody {
    display: flex;
    flex-direction: column;
    padding: 0.75rem 0;
  }
  .productName {
    order: 1;
    font-size: 1rem;
    margin: 0 0 .25rem;
  }  
  .brand {
    order: 2;
    font-size: .8125rem;
  }
  .productInfo {
    order: 3;
  }
}