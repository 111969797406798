.carouselOuter {
  position: relative;
  left: -0.5rem;
  width: calc(100% + 0.5rem);
  background-color: #fff;
  margin: .5rem auto 0;
  max-width: 1100px;
}

.carouselWrap {
  position: relative;
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding-left: 8px;
}

.carousel {
  position: relative;
  display: flex;
  justify-content: space-between;
  /* horizontal scroll mixin */
  composes: horizontalScroll from '../common/mixins.css';
  list-style: none;
  height: 118px;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 8px;
  font-size: 1rem;
  font-weight: 700;
  color: #767676;
  transition: height 0.2s ease-out;
}

.carousel::-webkit-scrollbar {
  display: none;
} 

/* DD goes 96w to 74w for items in Mobile */
.carouselItem {
  flex: 0 0 96px;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  padding: .5rem .25rem .5rem;
  margin:0 .75rem;
  border: 1px solid transparent;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 10px;
  border-radius: 1.5rem;
}

.carouselItem img {
  display: block;
  width: 50px;
  height: 50px;
  margin: 1.625rem auto 0;
  opacity: 1;
  transition: opacity 0.2s ease-out;
}

.carouselItem .label {
  position: absolute;
  left: 0;
  top: .625rem;
  width: 100%;
  text-align: center;
  font-size: .75rem;
  font-weight: 500;
  color: #000;
  transition: font-size 0.2s ease-out;
}

.carouselItem:first-of-type {
  margin-left: 0;    
}

.carouselItem:last-of-type {
  margin-right: 0;    
}

.selectedCategory,
.carouselItem:hover {
  color: #2c2c2c;
  background-color: #e6e7e8;
  border-color: #ccc;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 14px;  
}

.selectedCategory {
  cursor: default;
}

.itemMask {
  display: none;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  width: 3rem;
  background-color: #fff;
}

.firstItemMask {
  left: 0;
  -webkit-mask-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255) 32px, rgb(255, 255, 255) 100%);
}

.lastItemMask {
  right: 0;
  -webkit-mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255) 32px, rgb(255, 255, 255) 100%);
}

.withLeftArrow .firstItemMask,
.withRightArrow .lastItemMask {
  display: block;
}

.scrollArrow {
  cursor: pointer;
  position: absolute;
  display: none;
  opacity: 0;
  z-index: 2;
  top: 59px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  border-radius: 50%;
  transition: opacity .5s ease-in-out;
}

.controlsWrap {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  box-sizing: border-box;
}

.scrollLft {
  left: .75rem;
}

.scrollRgt {
  right: .75rem;
}

.withLeftArrow .scrollLft,
.withRightArrow .scrollRgt {
  display: block;
  opacity: 1;
}

@media screen and (max-width:480px) {
  .carouselItem {
    flex: 0 0 86px;
    font-size: .875rem;
  }
}
