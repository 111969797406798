/* OrderTypeToggle */
.orderTypeToggle {
    position: relative;
    font: var(--zrFont);
    font-size: .875rem;
    font-weight: 400;
    color: #000;
    display: flex;
    margin: 0 1rem;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
  }
  
  .toggleAlert {
    font: var(--zrFont);
    font-weight: 600;
    font-size: .6875rem;
    line-height: 1;
    position: absolute;
    z-index: 1;
    top: -4px;
    right: -24px;
    padding: 3px 6px;
    box-sizing: border-box;
    border-radius: .5rem;
    background-color: var(--zrOrange);
    color: #000;
  }
  
  /* nav styles */
  .orderTypeToggle.inNav {
    position: absolute;
    z-index: 1;
    left: 3.5rem;
    height: 35px;
    top: 50%;
    transform: translateY(-52%);
    background-color: #eee;
    border-radius: .25rem;
    padding: 0 .25rem;       
  }
  
  .orderTypeToggle.inBody {
    display: inline-block;
    background-color: #e6e7e8;
    border-radius: 100px;
    margin: 0 2rem;
  }
  
  /* OrderTypeToggle */
  .orderTypeToggle .toggle {
    line-height: 1;   
    padding: 6px 6px 5px;
    box-sizing: border-box;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
  } 
  
  /* TODO: enabled delivery */
  .orderTypeToggle .toggle.disabled {
    color: #666;
    cursor: default;
  }
  
  .orderTypeToggle.inBody .toggle {
    line-height: 40px;
    padding: .5rem 1rem;
    margin: 0;
  }
  
  /* OrderTypeToggle */
  .orderTypeToggle .toggle.selectedToggle { 
    cursor: default;
    background-color: #fff;
    font-weight: 800;
    border-radius: .5rem;
    border: 1px solid #ccc;
  } 
  
  .orderTypeToggle.inBody .toggle.selectedToggle { 
    color: #fff;
    background-color: #000;
    border-radius: 100px;
  }
  
  @media screen and (max-width:600px) {
    .orderTypeToggle.inNav {
      display: none;
    }
  }

  @media screen and (max-width:480px) {
    .orderTypeToggle.inBody {
      margin-top: 1rem;   
    }  
  }
  