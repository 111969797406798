/** 
 * Reg Flow is all green background-color 
 * body must be styled to prevent glimpses of white at top and bottom
 * Need to handle this dynamically 
 */ 
/* body { background-color: #5EB70B;} */

/** unused in sign in  **/
.formOnlyPage {
  min-height: calc(100vh - 12rem); /* adjust for header/footer */
  color: #fff;
  text-align: center;
  box-sizing: border-box;
}

/* suppress increment on zip code input */
.formOnlyPage input[type=number]::-webkit-inner-spin-button, 
.formOnlyPage input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.regFlowHeader {
  position: relative;
  padding: 12px;
  text-align: center;
  margin: 0 3rem 1rem;
}

.backLink {
  position: absolute;
  left: 0;
  top: 22px;
  text-decoration: none;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}

.regFlowHelp {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -14px;
  height: 24px;
  width: 24px;
  border: 1px solid #fff;
  border-radius: 50%;
  line-height: 24px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.regFlowlogo {
  display: inline-block;
  height: 36px;
  margin-top: 12px;
}

.photoID {
  display: block;
  height: 100px;
  margin: 2rem auto;
}

.completeShield {
  display: block;
  height: 100px;
  margin: 2rem auto;
}
.aeropayLogo {
  display: block;
  width: 250px;
  margin: 2rem auto 1rem;
}

.aeropayNote {
  line-height: 133%;
  font-size: 1.125rem;
}

.aeropayLink {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

.aeropayLink:hover {
  text-decoration: underline;
}

.verifyMethod {
  font-weight: 600;
}
.verifyMethod :global .MuiCheckbox-root {
  color: #fff;
}

/*** START modal form  ***/

.modalForm {
  width: 456px;
  max-width: 82vw; /* for modal display in mobile (Log In) */
  box-sizing: border-box;
  margin: 0 auto 8rem;
}

/* for non-modal flows/views */
.regFlowForm {
  width: 456px;
  max-width: 93vw; /* maximize for mobile */
  box-sizing: border-box;
  margin: 0 auto;
  padding-bottom: 2rem;
}

/* .regFlowForm overrides form completion page */
.regFlowCompleteForm {
  width: 600px;
}

.formHeader {
  color: #fff;
  text-align: center;
  font: var(--zrHeaderFont);  
  font-size: 2rem;
  font-weight: 800;
  margin: 0 -50px 1.25rem;
}

.formHeaderSecondary {
  color: #fff;
  text-align: center;
  font: var(--zrHeaderFont);  
  font-size: 1.5rem;
  font-weight: 800;
  margin: 0 0 2rem;
}


.headerWrap.nonModal {
  margin: 2rem 0 1rem;
}

@media screen and (max-width: 800px) {
  .headerWrap.nonModal {
    padding-top: 2rem;
  }
  .formHeader {
    font-size: 1.5rem;
  }  
  .formHeaderSecondary {
    font-size: 1.25rem;
  }   
}  

@media screen and (max-width:556px) {
  .formHeader {
    margin: 0 0 2rem;
  }
}

.formButton,
.formInput {
  cursor: pointer;  
  display: block;
  box-sizing: border-box;
  width: 100%;  
  padding: 1rem;
  font-size: 1.2rem; /* 19.28px */
  margin: 1rem 0;
  border: 1px solid #ccc;
  border-radius: 2px;
} 

.formInput {
  background-color: #fff;
}

.formButton {
  background-color: #60B445;
  color: #fff;
  font: var(--zrHeaderFont);
  font-size: 1.25rem;
  font-weight: 700;
  border-radius: .25rem;
}

.formButton.checkButton {
  position: relative;
  padding-left: 68px;
}

.checkButton svg {
  position: absolute;
  left: 7px;
  top: 7px;
}

.formSubmit {
  display: inline-block;
  width: auto;
  min-width: 300px;
  border-color: var(--zrDarkBlue);
  border-radius: .25rem;
  background-color: var(--zrDarkBlue);
  margin-left: auto;
  margin-right: auto;
}

.formInstructions {
  font-size: 1.0625rem;
  margin:2rem 0 .75rem;
}

.formLink {
  color: #fff;
  margin:.5rem 0;
  font-size: 1.1rem;  /* 17.6px */ 
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
}

.headerLink {
  font-size: 1.125rem;
  font-weight: normal;
  cursor: pointer;
}

.signInLink {
  color:#333;  /* figma #373838 */
  font-size: 18px;  
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
}

.formAlignRight {
  text-align: right;  
}

.loginForgotPassword {
  text-align: right;
  margin-top: -.5rem;
}

.formButtonLink:focus,
.formButton:focus,
.formInput:focus {
  outline: none;
  box-shadow: 0 0 5px var(--zrGreen);  
  border-color: var(--zrGreen);
}

.formSignUpButton {
  display: inline-block;
  box-sizing: border-box;
  max-width: 7rem; /* Sign Up */ 
  font-size: 1rem;
  margin: 0;
  border: 1px solid #60B445;
  border-radius: 2px;  
  color:#60B445; 
  background-color: #fff;;
  text-align: center;
  text-decoration: none;
}

.forgotPasswordLink {
  position: relative;
  color: #60B445;
  font-size: 1rem;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
}

.formError {
  /* handle error on green */
  background-color: #ffffffcc;
  padding: .75rem;
  border-radius: .5rem;
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  color: var(--zrTextRed);
  margin: 1rem 0;
}

.formError span,
.formError :global(.MuiSvgIcon-root) { 
  vertical-align: middle;
}

.termsBox {
  position: relative;
  text-align: left;
  font-size: .75rem;
  line-height: 125%;
  padding: .75rem 1rem .75rem 41px;
  margin: 0 0 1.5rem;
  background-color: #ffffffbb;
  border-radius: .25rem;
  color: #292b2d;
}

.termsBox :global .MuiFormControlLabel-root {
  margin-left: 0;  
}

.termsBox :global .MuiFormControlLabel-root .MuiTypography-root {
  line-height: 125%;
}

.termsBox :global .MuiButtonBase-root {
  position: absolute;
  top: 0;
  left: 0;
}

.termsBox :global .MuiCheckbox-root {
  color: #292b2d;
}

.termsError {
  padding: .5rem .8rem;
  border-top: 2px solid #f44336;
  font-size: .8rem;
  text-align: left;  
}
.termsError svg,
.termsError span {
  margin-right: .5rem;
  vertical-align: middle;
}

.inputFeedback {
  position: relative;
  height: 0;
  font-size: .75rem;
  z-index: 1;
}  

.inputFeedback .inputSuccess {
  color: green;
  position: absolute;
  top: .5rem;
  right: 1rem;
}

.inputFeedback .inputFailure {
  color: var(--zrTextRed);
  position: absolute;
  top: .5rem;
  right: 1rem;
}

.addressInputPair { 
  display: flex;
}
.addressInputPair :global .MuiFormControl-root.MuiTextField-root:first-of-type {
  flex: 2;
  min-width: 65%;
}

.addressInputPair :global .MuiFormControl-root.MuiTextField-root:last-of-type {
  margin-left: 1rem;
  flex: 1;
}

.calWrap {
  font-size: 1rem;
  text-align:left;
}

.calWrap :global .MuiFormControl-root.MuiTextField-root {
  margin-left: 1rem;
}

.calWrap :global .MuiFormControl-root.MuiTextField-root:first-of-type {
  margin-left: 0;
}

.calEntries {
  display: flex;
  margin: .25rem 0 0;
}
.calEntries .formInput {
  margin: 1rem;
}

.calEntries .formInput:first-of-type {
  margin-left:0;
}

.calEntries .formInput:last-of-type {
  margin-right:0;
}

.signInWrap {
  margin: 0 0 1rem;
}

.twoCol {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  white-space: nowrap;    
}

.twoCol>span {
  flex:1;
}  

/** PhoneInput styles for the 'react-phone-number-input' component **/

:global .PhoneInput {
  position: relative;
}

/* country select */
:global .PhoneInputCountrySelect {
  position: absolute;
  z-index: 2;
  top: 11px;
  right: 10px;
  width: 65px;
  padding: 10px;
  opacity: 0;
  cursor: pointer;
}

:global .PhoneInputCountrySelectArrow {
  top: 14px;
  right: 15px;
  position: absolute;
  height: 20px;
  width: 20px;
}

:global .PhoneInputCountrySelectArrow:before {
  position: absolute;
  top: 0;
  right: 0;
  content: '\25B2';
  font-size: 14px;
  color: #999;
}

:global .PhoneInputCountrySelectArrow:after {
  position: absolute;
  top: 14px;
  right: 0;
  content: '\25BC';
  font-size: 14px;
  color: #999;
}

:global .PhoneInputCountryIconImg {
  padding: 8px 23px 8px 8px;
  right: 10px;
  position: absolute;
  height: 20px;
  top: 11px;
  border: 1px solid #ccc;
}

/* phone input  - style like the Material UI inputs */
.phoneInputWrapper {
  position: relative;
  margin: 0 0 2rem;
}

:global .PhoneInputInput {
  font-size: 1.2rem;
  border-radius: 3px;
  background-color: #edf7f3;
  width: 100%;
  padding: 19px 1rem 16px;
  border-bottom: 2px solid #edf7f3;
  border-width: 0 0 2px;
  box-sizing: border-box;
}

.phoneInputError :global .PhoneInputInput {
  color: #f44336;
  border-bottom: 2px solid #f44336;  
}

.phoneInputError :global .PhoneInputInput:focus {
  color: #000;
  border-bottom: 2px solid #f44336;
}

:global .PhoneInputInput:focus {
  background-color: #fff;
  outline: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}

.phoneNumberErrorMsg {
  color: #fff;
  font-size: .75rem;
  text-align: left;
  margin: 0;
  padding: .2rem .8rem;
}

.phoneNumberErrorMsg :global .MuiSvgIcon-root {
  vertical-align: middle;
  margin-right: .5rem;
}

/* Phone - overrides !important due to load order issues */
@media screen and (max-width:480px) {
  :global .MuiFormControl-root .MuiFormHelperText-root.Mui-error {
    padding: .2rem !important;
  }

  :global .MuiFormControl-root .MuiFormHelperText-root.Mui-error span {
    margin: 0 .25rem !important;
  }
  .termsError svg,
  .termsError span {
    margin-right: .25rem;
  }
}  
