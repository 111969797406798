.placeOrderGrid {
  display: flex;
}

.placeOrderCol {
  flex:4;
}

.dispensaryColLayout {
  box-sizing: border-box;
  padding: 0 2rem;
  max-width: 1364px;
  margin: 0 auto;
}

.cartCol {
  border-left: 1px solid #e7e7e7;
  flex: 0 0 339px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  position: relative;
  z-index: 1; /* for modal stacking */
}

.placeOrderWrapper {
  max-width: 800px;
  margin: 0 auto;    
}

.paymentMethods {
  font-size: .875rem;
  margin-top: .75rem;
}

.paymentMethods .paymentLabel,
.paymentMethods .paymentValues {
  white-space: nowrap;
}

.paymentMethods .paymentValue {
  margin-left: 1rem;
}

.orderFrom {
  line-height: .75rem;  
  font-size:.75rem;
  color: #767676;
}

.orderStore {
  line-height: 1.5rem;
  font: var(--zrHeaderFont);  
  font-size: 1.5rem;  
  font-weight: 800;
  color: var(--zrBlack);    
}

.orderStore:hover {
  text-decoration: underline;
  cursor: pointer;
}

.section {
  display: flex;
  padding: 1rem;
  border-bottom: 1px solid #e6e7e8;  
}

.section:last-of-type {
  border:none;
}

.sectionTitle {
  flex: 0 1 160px;
  font-weight: 700;
  margin: 0 0 1rem;    
}

.sectionInner {
  flex: 1;
  font-weight: 500;  
  color: #555;    
}

.sectionInner label,
.sectionInner input[type="radio"] {
  margin: 0 .5rem 0 0;    
}

.disabled {
  color: #c0c0c0;
}

.radioWrapper {
  display: flex;
  margin-bottom: .5rem;
}
.radioWrapper input[type="radio"] {
  margin: .2rem .5rem 0 0;
}

.radioWrapper input,
.radioWrapper label {
  cursor: pointer;
}

.cartItem { 
  display: flex;
  justify-content: left;
  margin: 0 0 .25rem;
}

.itemCount,
.itemPrice {
  flex: 1;
}

.itemTitle {
  flex: 10;    
}

.itemPrice {
  text-align: right;    
}

.removeFromOrder {
  font-size: .75rem;
  color: var(--zrGreen);
  margin-left: .5rem;
  vertical-align: 4px;
  cursor: pointer;
}

@media screen and (max-width:800px) {
  .section { 
    display: block;
  }
}

@media screen and (max-width:600px) {
  .paymentMethods .paymentLabel,
  .paymentMethods .paymentValues {
    display: block;
  }
  .paymentMethods .paymentValue {
    margin-left: 0;
    margin-right: .75rem;
  }
  .paymentValues {
    margin-top: .5rem;
  }   
}