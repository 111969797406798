.resetWrap {
  margin: 6rem 0 0;
  padding-top: 2rem; /* for mobile */
  width: 500px;
  max-width: calc(100vw - 2rem);
  margin-left: auto;
  margin-right: auto;
}

.updateError,
.invalidCode {
  text-align: center;
  font-style: italic;
  font-size: 1.2rem;
  line-height: 133%;
  margin: 1rem 0;    
}

.updateError {
  color: red;   
}

.successMsgWrap {
  text-align: center;
}    

.successMsg {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0 1.2rem;
}

/* reset email form */

.plainForm {
  width: 456px;
  max-width: 82vw; /* for modal display in mobile (Log In) */
  box-sizing: border-box;
  margin: 0 auto;
}

.plainForm :global(.MuiFormControl-root .MuiFormHelperText-root.Mui-error) {
  color: var(--zrGreen);
  border-bottom: 1px soen;    
}

.headerWrap {
  margin: 2rem 0 1rem;
}

.resetHeader {
  color: #4d4d4d;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin: 0 0 2rem;
}

.cancel {
  cursor: pointer;
  color: var(--zrGreen);
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.emailError svg,
.plainForm :global(.MuiFormControl-root .MuiFormHelperText-root.Mui-error) svg {
  vertical-align: middle;
}

.emailSuccess {
  color: var(--zrGreen);
}

.emailError {
  color: red;
}

.emailSuccess,
.emailError {
  text-align: center;
  font-weight: 500;
  margin: 0 0 .75rem;
}

.plainInputs :global(.MuiFilledInput-root .MuiFilledInput-input) { background-color: rgb(236, 236, 236); }
.plainInputs :global(.MuiFilledInput-root .MuiFilledInput-input):active,
.plainInputs :global(.MuiFilledInput-root .MuiFilledInput-input):hover { background-color: rgb(246, 246, 246); }
