.noOrdersMsg {
  margin: 2rem 0;
  text-align: center;
  font-size: 1.2rem;
}

.shopLink {
  cursor: pointer;
  font-weight: 600;
  color: var(--zrGreen);
}
.shopLink:hover {
  text-decoration: underline;
}

.orderWrap {
  padding-top: 1.5rem;
  max-width: 928px;
  margin: 0 auto;
}

.orderDetails {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: .5rem;
  margin: 1rem 0 2rem;
}

/* Canceled order styles */
.statusHelp dt.cancelTerm,
.canceled .status,
.canceled .orderDetailHdr svg {
  color: #aa1212;
}
.canceled .orderTotal {
  color: #595959;
  text-decoration: line-through;
}

/* TODO add in dispensary link  */
.orderDispensary {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 0 .5rem;
  color: var(--zrGreen);
  cursor: pointer;
}

.orderDispensary:hover {
  text-decoration: underline;   
}

.orderDetailHdr {
  display: grid;
  color: #595959;
}

.headerNote {
  font-size: .75rem;
  vertical-align: middle;
  font-weight: 400;
  padding: 0 .5rem;
}

.orderDetailHdr .col1 { grid-column: 1;}
.orderDetailHdr .col2 { grid-column: 2;}
.orderDetailHdr .col3 { grid-column: 3;}
.orderDetailHdr .col4 { grid-column: 4;}

.orderDetailHdr .orderDetailHdrItem { 
  padding:.75rem 1rem; 
  background-color: rgba(0,0,0,.05);
}

.orderDetailHdr .orderDetailHdrItem.statusRow { background-color: #fff; text-transform: uppercase;}
.orderDetailHdr .statusRow.col4 { 
  grid-column: 4/5;
  justify-self:end;
}

.orderItemsHeader,
.orderDetailHdrLbl {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  margin:0 0 .5rem;
  white-space: nowrap;
  color: #595959;
}  

.orderDetailHdrVal {
  font-size: 14px;
  color: #448607;
  white-space: nowrap;
  font-weight: 600;
}

.orderDetailHdrVal .paymentLabel {
  font-weight: 500;
  text-transform: none;
}

.orderDetailHdrVal.deliveryWindow {
  text-transform: none;
}

.orderItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0 0;
}

.orderedProducts {
  padding: 1rem 1rem 0;
}

.link {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

/* ActiveOrdersWarning.jsx */
.activeOrdersWarning {
  position: relative;
  background-color: #54a40a33;
  border-radius: .25rem;
  padding: 1rem 1rem 1rem 3rem;
  font-size: 1.125rem;
}

.activeOrdersWarning svg {
  position: absolute;
  top: .9rem;
  left: 1rem;
}

.activeOrdersWarning .yourOrdersLink {
  font-weight: 600;
  cursor: pointer;
}

.activeOrdersWarning .yourOrdersLink:hover {
  text-decoration: underline;
}

/* long order Ids require an early tablet layout */
@media screen and (max-width:1000px) {
  .orderDetailHdr .col3 { grid-column: 3/5;}
  .orderDetailHdr .col4 { grid-column: 1/5;}

  .orderDetailHdr .statusRow.col3 { 
    grid-column: 3;
    justify-self: start;
  }
  .orderDetailHdr .statusRow.col4 {
    grid-column: 4;
    justify-self: start;
  }
}

@media screen and (max-width:600px) {
  .activeOrdersWarning,
  .noOrdersMsg {
    font-size: 1rem;
  }
  
  .orderItems {
    display: block;
  }

  .orderDetailHdr .col1 { grid-column: 1/3;}
  .orderDetailHdr .col2 { grid-column: 3/5;}
  .orderDetailHdr .col3 { grid-column: 1/5;} /* long order Id */
  .orderDetailHdr .col4 { grid-column: 1/5;}
  
  .orderId {
    font-size:.8rem;
  }

  .orderDetailHdr .orderDetailHdrItem { 
    padding: .75rem .5rem; 
  }
  
  .orderDetailHdr .statusRow { background-color: #fff; text-transform: uppercase;}
  .orderDetailHdr .statusRow.col3 { 
    grid-column: 1/3;
    justify-self: start;
  }
  .orderDetailHdr .statusRow.col4 {
    grid-column: 3/5;
    justify-self: start;
  }
}
/* Status Modal */

.status,
.statusHelp dt {
  text-transform: uppercase;   
}
.statusHelp dt {
  font-weight: 600; 
  margin: .5rem 0;
  color: var(-zrgreen);
}   
