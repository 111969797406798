/* adjust height for modal view */
.imageOuter.wideImage {
  height: 300px;
  position: relative;  
}

/* for positioning images */
.imageWrap {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: .25rem;
}

.imageOuter:not(.wideImage) .imageWrap {
  top: .75rem;
  right: .75rem;
  width: calc(100% - 1.5rem);
  height: calc(100% - 1.5rem);
  border-radius: .25rem;
}

.productImage {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 100%;
  max-width: 120%; /* compromise to better handle lansdscape images (but you can lose rounded corners) */
  transform: translate(-50%, -50%);
  border-radius: .25rem;
}

/* handle portrait image in modal */
.blurredImage {
  width: 100%;
  height: 100%;
  filter:blur(10px);
}

/* this was for full bleed */
.imageOuter:not(.wideImage) .blurredImage {
  display: none;
}

.imageOuter.wideImage .imageWrap .productImage {
  max-height: 110%; /* compromise to better handle portrait images */ 
}

.imageOuter.wideImage.stockImage .imageWrap .productImage {
  max-height: 120%; /* stock images have padding, so dial up height */ 
}

/* image coming soon overlay */
.placeholderMessage {
  position: absolute;
  top: 75%;
  left: .75rem;
  right: .75rem;
  transform: translateY(-50%);
  border-radius: 24px;
  font-size: .5625rem;
  font-weight: 600;
  line-height: 1.375rem;
  overflow: hidden;
}

.wideImage .placeholderMessage {
  left: 8%;
  right: 8%;
  font-size: 1.125rem;
  line-height: 2.75rem;
}

/* tablet product page */
@media screen and (max-width:800px) {
  .imageOuter.wideImage {
    height: 250px;
  }
  .placeholderMessage {
    font-size: .5rem;
    left: 0;
    right: 0;
  }
  .wideImage .placeholderMessage {
    line-height: 2.375rem;
  }  
}

/* adjust modal for mobile */
@media screen and (max-width:480px) {
  .imageOuter.wideImage {
    height: 200px;
  }
}