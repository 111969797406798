.promo {
  border: 2px solid;  
  margin-top: 1.5rem;  
  padding: 1.5rem 3rem;
  font-size: .875rem;   
}

.promo p {
  line-height: 140%;
}

.innerGrid {
  display: flex;
  column-gap: 1rem;
}

.gridCol {
  flex: 1;
}

/* single column below iPad width */
@media screen and (max-width:767px) {
  .innerGrid {
    display: block;
  }
}