/* Outer menu wrapper */
.pageLayout {
  position: relative;
  box-sizing: border-box;
  min-height: calc(50vh);
  padding-bottom: 0;
}

/* home page */
.headerWrap {
  margin-top: 2rem;
}

@media screen and (max-width:600px) {
  .headerWrap {
    margin-top: 1rem;  
  }
}