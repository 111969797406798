.sectionHeader {
  margin-top: 2rem;
  flex-basis: 100%;    
}

.dispensaryName {
  display: inline-block;
  margin-right: .75rem;
}

.seeAllLink {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  float: right;
}

.buttonWrap {
  margin: -.5rem 0 1rem;
  flex: 1 1 100%;
}

.buttonWrap button {
  min-width: auto;
  max-width: 90%;
}

@media screen and (max-width:600px) {
  .seeAllLink .viewAllDetails {
    display: none;
  }
}