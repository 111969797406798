.blacklistWarning {
  position: relative;  
  margin: 0 0 1rem;  
  padding: .75rem 1rem .75rem 41px;
  background-color: #00000011;
  border-radius: .25rem;
  color: #292b2d;
  text-align: left;
  line-height: 125%;    
}

.blacklistWarning.onDarkBackground {
  background-color: #ffffffbb;
}

.blacklistWarning .warningSymbol {
  position: absolute;
  left: 9px;
  top: 12px;
  font-size: 1.675rem;
}