.filterWrap {
  position: relative;
  margin: 0 -1rem;
  padding: 2rem 0 3rem;
}

.hdr {
  position: absolute;
  z-index: 1;
  top: -44px;
  width: calc(100% + 2rem);
  padding: 56px 0 8px;
  margin-left: -1rem;
  font: var(--zrHeaderFont);
  font-size: 1.5rem;
  text-align: center;
  letter-spacing: 2px;
  color: #fff;
  background-color: #272a3e;
}

.hdrIcon {
  margin-right: 8px;
  vertical-align: -4px;
}

.scrollingContent {
  overflow-y: auto;
  min-height: calc(72vh - 160px);
  max-height: calc(72vh - 160px);
  padding: 17px 0 32px;
  background-color: #eeeeee99;
}

.resetWrap {
  text-align: center;
  border-top: 1px solid #fff;
  padding: 1rem 0;    
}

.buttonWrap {
  display: flex;
  justify-content: center;
  position: absolute;
  left: -1rem;
  right: -1rem;
  bottom: 0;
  background-color: #fff;
  border-top: 2px solid #000;
  padding-top: 8px;  
}

.buttonWrap button {
  margin: 8px auto;
}    