/* Google Location Search in modal positioning hack/fix */
body[data-modal-open="true"] :global .pac-container {
  position: fixed !important;
}

.modalContent {
  box-sizing: border-box;
  position: fixed;
  z-index: 10; /* above all  */
  overflow: hidden;
  color: #2c2c2c;
  background-color:#fff;
  border-radius: .75rem;
  width: 600px;
  max-width: 95vw; 
  min-height: 8rem;
  max-height: 80vh;
  padding: 2rem 1rem 1rem;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
}

.modalInner {
  padding: 0;
  text-align: left;
}

.scrollingInner {
  height: 27rem;
  max-height: calc(75vh - 4rem);
  overflow-y: auto;
}

.modalContent .closeIcon {
  cursor: pointer;
  position: absolute;
  z-index: 5;
  left: .5rem;
  top: .5rem;
  height: 1rem;
  background-color: #fff;
  padding: .625rem;
  border-radius: 50%;
  box-shadow: 0 0 3px #13460188;
}

.modalBGElem {
  z-index: 10;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.35);
}
@media screen and (max-width:480px) {
  .modalContent { 
    padding: 2.75rem .5rem .5rem;
  }
  .modalInner {
    padding: 0 .5rem;
  }  
}