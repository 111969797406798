/* Berkshire Roots is 155px + 36px right margin for filter column */

/**
 * Set in Dispensary.module.css for wrapper:
 *
 * .filterCol
 *   flex: 0;
 *   max-width: 160px;
 */

.subCategoryFilter {
  box-sizing: border-box;
  padding: .75rem .5rem .75rem 0;
  font-size: .875rem;
  min-width: 160px;      
}

.subCategoryFilter::after {
  display: block;
  height: 0;
  content: '';
  border-bottom: 1px solid #e6e7e8;
}

.sectionHdr {
  font-weight: 700;
  margin-bottom: .75rem;    
}

.filterLinks {
  margin: .75rem 0 0;  
}

.filterLink {
  margin: 1rem 0;
}

.filterLink:first-of-type {
  margin-top: .5rem;
}

.filterLink.selected {
  font-weight: 700;    
} 

.filterLink:hover {
  cursor: pointer;
}

.rightEdgeMask {
  display: none;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 1px;
  bottom: 1px;
  width: 2rem;
  background-color: #fff;
  -webkit-mask-image: linear-gradient(90deg,
   rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255) 16px, rgb(255, 255, 255) 100%);
}

/* Alternate pill horizontal layout */
@media screen and (max-width:1024px) {
  .subCategoryFilter {
    flex-basis: 100%;
    max-width: 100%;
    overflow: hidden;
    padding-top: 0;
    font-size: .75rem;
    position: relative;
    padding-bottom: .75rem;
  }
  .subCategoryFilter::after {
    display: none;
  }

  .rightEdgeMask {
    display: block;
  }
  .sectionHdr {
    display: none;    
  } 
  .filterLinks {
    padding-right: 2rem; /* mask */
    margin: .25rem 0;
    white-space: nowrap;
    /* horizontal scroll mixin */
    composes: horizontalScroll from '../common/mixins.css';
  }
  .filterLink {
    margin: 0 .5rem;
    background-color: #eaf2eacc;
    padding: .5rem .75rem;
    border-radius: 16px; 
    display: inline-block;    
  }
  
  .filterLink:first-of-type {
    margin-top: 0;
  }

  .filterLink.selected {
    background-color: #54a40a66;    
  }
  .filterLink:first-of-type {
    margin-left: 0;    
  }
}
