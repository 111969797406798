/* Mostly to keep it pretty in MS Edge */ 
.horizontalScroll {
  overflow-x: auto;
  overflow-y: visible;
  -ms-overflow-style: none; /* Older Internet Explorer, Edge */
  scrollbar-width: none;    /* Firefox - nice with horizontal scroll */
}

.horizontalScroll::-webkit-scrollbar {
  display: none;
}  

.verticalScroll {
  overflow-x: visible;
  overflow-y: auto;
  -ms-overflow-style: none; /* Older Internet Explorer, Edge */
  scrollbar-width: none;    /* Firefox */  
}

/* 1 line with ellipsis */
.ellipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;   
}

/* 2 lines with ellipsis */
.twoLines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.XverticalScroll::-webkit-scrollbar {
  width: 12px;
  padding: 2px;
  background-color: transparent;
}

/* Apply MacOS Chrome scrollbar styling to Windows Edge */
body[data-is-edge="true"] .XverticalScroll::-webkit-scrollbar-thumb {
  width: 14px;
  background-color: #7f7f7f; /* Chrome */
  border-radius: 5px;
}