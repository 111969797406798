/** BUTTONS */

.button {
  cursor: pointer;
  width: auto;
  min-width: 50%;
  margin-top: 1rem;  
  background-color: var(--zrDarkGreen);
  color: #fff;
  padding: .75rem 3rem;
  font: var(--zrHeaderFont);
  font-weight: 800;
  font-size: 1rem;
  border: none;
  border-radius: 4px; /* 20px */
  white-space: nowrap;
  composes: ellipses from '../common/mixins.css';  
}

button.button:focus {
  outline: none;
}

button.button:hover {
  box-shadow: 0 0 3px #000, 0 0 6px #076986;
}

button.disabledButton {
  cursor: default;
  background-color: #d3d3d3 !important; /* overwrite theme! */
  color: #fff;
}

button.disabledButton:hover {
  box-shadow: none;
}

/* for DisabledButtonWithReason */
.disabledMsg {
  margin-top: .25rem;
  padding: 0 .25rem;
  font-style: italic;
  font-weight: 600;
  font-size: .875rem;
  line-height: 133%;
  text-align: center;
}

.disabledMsgSup {
  vertical-align: 4px;
  margin-right:2px;
}

/* ButtonWithPrice  variant */
.priceButton {
  display: flex;
}

.priceButton .buttonText {
  padding: 0 .25rem;
  text-align:left;
  flex: 10;  
}

.priceButton .buttonPrice {
  padding: 0 .25rem;
  flex: 1;  
}

.basicButton {
  color: #333;
  width: auto;
  margin-top: 0;
  min-width: auto;
  padding: .5rem 1rem;
  background-color:#ccc;
  border: 1px solid #999;
}

.detourButton {
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 800;
  border-width: 2px;
  border-style: solid;
  border-radius: .25rem;
  padding: .75rem 2rem;
}

/* start mobile button sizing at 600 */
@media screen and (max-width:600px) {
  .button {
    min-width: 75%; /* see place order buttons */
    padding: .75rem .75rem; 
  }  
}
