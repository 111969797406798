.progressWrap {
  padding: .5rem;
}

.progressWrap svg {
  display: block;
  height: 80px;
  margin: 0 auto 1rem;
}

:global .CircularProgressbar .CircularProgressbar-text {
  text-anchor: middle;  
  dominant-baseline: central;
  font-weight: 800;
}    