.dispensaryCol {
  flex:4;
  min-height: 75vh; /* prevent content collapse on product filter */
}

.dispensaryColLayout {
  box-sizing: border-box;
  padding: 0 2rem;
  max-width: 1120px; /* pre-filters: 960px; with cart column? 1364px; */
  margin: 0 auto;
}

/* @ wide screen break point - no cart column */
@media screen and (max-width:1405px) {
  .dispensaryColLayout {
    max-width: calc(100vw - 8px); /* fix horiz scroll in mobile */
  }
}

.cartCol {
  border-left: 1px solid #e7e7e7;
  flex: 0 0 339px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  position: relative;
  z-index: 1; /* for modal stacking */
}

.productGridWithFilters {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  min-height: 60vh;
  width: 1100px;
  max-width: 100%;
  margin: 0 auto;
}

.headerWithFilters {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-basis: 100%;
  margin-top: 1.125rem;
}

.hdrFilterWrap {
  display: none;
  cursor: pointer;
  font: var(--zrFont);
  font-weight: 600;
}

.filterIcon {
  margin-right: .5rem;
  height: 20px;
  vertical-align: text-top;
}

.filterCol {
  flex: 0; /* part of productGridWithFilters grid */
  max-width: 160px;
}

.productGrid {
  flex: 1;
  margin-bottom: 2rem;
}


/* Suppress the left-side filters */
@media screen and (max-width:1024px) {
  .productGridWithFilters {
    min-height: 50vh /* prevent collapse when filtering */
  }
  .filterCol {
    flex-basis: 100%;
    max-width: 100%;
  }
  /* show the filter icon */
  .hdrFilterWrap {
    display: block;
  }
}  

@media screen and (max-width:800px) {
  .filterCarouselWrap {
    max-width: 100vw;
  }
}

/** 
 * Cart col removed from layout at 1200 
 * @media screen and (max-width:1200px) {
 */  

@media screen and (max-width:600px) {
  .dispensaryGrid {
    display: block;
  }

  .dispensaryCol {
    padding-bottom: 0; /* remove filter page jump padding on mobile */
  }

  .dispensaryColLayout {
    padding: 0 .75rem;
  }

  .productGrid {
    display: block;
  }
  .filterCarouselWrap {
    margin: 0 -1rem;
  }
}


/* product detail page within dispensary markup */
.productDetailPageWrap {
  width: 600px;
  margin: 1rem auto;
  max-width: calc(100vw - 2rem);
}
